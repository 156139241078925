import axios from "axios";
export const RESTORE_API_HOST_URL = process.env.REACT_APP_RESTORE_API_URL

export default async function Restore(accessToken, selectedFiles) {
    let returnResponse = {
        data: [],
        result: false,
        status: ""
    };
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };

    let _undoFileList = selectedFiles.map((i)=> { 
        return {fileName: i.path, createdDate: "NOTHING"}
    });

    const undoFileList = {
        undoFileList: _undoFileList
    };

    try {
        const response = await axios.post(
            `${RESTORE_API_HOST_URL}`,
            undoFileList,
            { headers: headers }
        );

        if (response.status === 200) {
            returnResponse.data = response.data;
            returnResponse.status = response.status;
            returnResponse.result = true;
        } else {
            console.error('Request failed with status:', response.status);
            returnResponse.status = response.status;
        }
    } catch (error) {
        console.error('Request error:', error);
        returnResponse.status = error;
    }
    return returnResponse;
}