import app, { auth, db } from "../config/firebase";
import { signOut } from "firebase/auth";

import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { clearEmailsList } from "../store/reducers/emailFilesReducer";
import {
  setCountry,
  setLanguage,
  setRoles,
  setTimeZone,
} from "../store/reducers/userReducer";

import {
  setFilesStore,
  setCurrentItems,
  clearCurrentItems,
  setMultipleFileSelect,
  clearMultipleFileSelect,
  addSelectedFile,
  removeSelectedFile,
} from "../store/reducers/filesReducer";
import {
  setSearchTerm,
  setSearchRefresh,
} from "../store/reducers/searchReducer";

import "../screens/HomeScreen.css";

import { RingLoader } from "react-spinners";
import { message, Tooltip } from "antd";

import UploadOverlay from "../components/UploadOverlay";
import FilePreview from "../components/FilePreview/FilePreview";
import RenameDialog from "../newComponent/RenameDialog";

import "../components/FilePreview/FilePreview";

import Header1 from "../newComponent/Header";
import SideBar1 from "../newComponent/SideBar";
import Axios from "axios";
import { jwtDecode } from "jwt-decode";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { positionContextMenu } from "../utils/contextMenuUtils";
import { setCurrentPage } from "../store/reducers/pageInfoReducer";

import { useUserDetails } from "../customHook/userDetails";
import SortableHeader from "../components/SortableHeader";
import CreateFolderModal from "../newComponent/FolderModal";
import CreateFolderService from "../services/createFolderService";
import { FilesandFolderDropService } from "../services/FilesandFolderDropService";
import { CircularProgress } from "@mui/material";
import ProgressIndicator from "../newComponent/Progress_Indicator";
import BreadcrumbComponent from "../newComponent/BreadCrumbsNavigation";
import EmptyFolderImage from "../newComponent/EmptyFolderImage";
import { allowedExtensions } from "../components/APP_LOGO/allowedExtensions";
import CutServiceForDragAndDrop from "../services/cutServiceForDragandDrop";
import CommonOverlay from "../newComponent/CommonOverlay";
import EmailScreen from "./EmailScreen";
import { useTranslation } from "react-i18next";

import { createFolderTree } from "../utils/folderUtils";
import { reapplySorting } from "../utils/sortUtils";
import { handleDropUtil } from "../utils/dropUtils";

import {
  contextMenuOptionClick,
  ContextMenuOptionsBuilder,
} from "../utils/contextMenuUtils";
import {
  handleIndividualDragStart,
  handleIndividualDrop,
  handleIndividualDragOver,
  handleIndividualDragLeave,
  handleIndividualDragEnd,
} from "../utils/dragAndDropUtils";

import ContextMenu from "../newComponent/ContextMenu";
import DocumentTopActionIcons from "../newComponent/DocumentTopActionIcons";
import DocumentGrid from "../newComponent/DocumentGrid";

export const READ_API_URL = process.env.REACT_APP_READ_API_URL;

export default function HomeScreen({ accessToken, setAccessToken }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [initialFileLoading, setInitialFileLoading] = useState(true);

  const [selectedFolder, setSelectedFolder] = useState("Home"); // Track selected folder
  const [files, setFiles] = useState([]); // Files from the backend

  const [filesData, setFilesData] = useState([]);

  const [currentFolderPath, setCurrentFolderPath] = useState(""); // State to store current folder path

  const [folderTree, setFolderTree] = useState({});
  // const [currentItems, setCurrentItems] = useState([]); // Items (files/folders) being displayed
  const [rootFolders, setRootFolders] = useState([]); // Root folders to display
  const [initialItems, setInitialItems] = useState([]); // New state for initial items

  const [checkedFiles, setCheckedFiles] = useState([]); // State to track checked files
  const [isAllSelected, setIsAllSelected] = useState(false); // State for the "Select All" checkbox

  const [messageApi, messageContextHolder] = message.useMessage();

  const [isUploadOverlayOpen, setIsUploadOverlayOpen] = useState(false);

  const [isEmailOverlayOpen, setIsEmailOverlayOpen] = useState(false);
  const [selectedFilesToEmail, setSelectedFilesToEmail] = useState([]);

  const [isFilePreviewOverlayOpen, setIsFilePreviewOverlayOpen] =
    useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFilePath, setSelectedFilePath] = useState();
  const [currentFileIndex, setCurrentFileIndex] = useState();

  const [expandedFolders, setExpandedFolders] = useState([]); // Keep track of expanded folders

  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false); // State for Rename Dialog
  const [fileToRename, setFileToRename] = useState(null); // Track file to rename

  const [isAscending, setIsAscending] = useState(true); // State for sorting order

  const [isCreateFolderModalOpen, setIsCreateFolderModalOpen] = useState(false);

  const [dragActive, setDragActive] = useState(false);
  const [folderFiles, setFolderFiles] = useState([]);

  const [selectedFileForNewFolder, setSelectedFileForNewFolder] =
    useState(null);

  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    options: [],
  });

  const { userDetail, refreshAccessToken } = useUserDetails();

  // const [accessToken, setAccessToken] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const selectedIndexRef = useRef(selectedIndex); // Ref to store selectedIndex
  const [contextMenuIndex, setContextMenuIndex] = useState(-1);

  const [copiedPaths, setCopiedPaths] = useState([]);
  const [cutPaths, setCutPaths] = useState([]);
  const [operationType, setOperationType] = useState(""); // Can be "cut" or "copy"

  const [progress, setProgress] = useState(0); // Track download progress
  const [isDownloading, setIsDownloading] = useState(false); // Show/hide progress indicator

  const [downloadStatus, setDownloadStatus] = useState(""); // To track the status text

  const [numberOfDownloadedFiles, setNumberOfDownloadedFiles] = useState(0);

  const [isUploading, setIsUploading] = useState(false);

  const [sidebarWidth, setSidebarWidth] = useState(200); // Initial sidebar width

  const [sortKey, setSortKey] = useState("date");

  const [sideBarRefreshName, setSideBarRefreshName] = useState({
    name: "",
    event: null,
  });

  const [isMessageProcessing, setIsMessageProcessing] = useState(false);

  const [hoveringItems, setHoveringItems] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  const { i18n } = useTranslation();

  // Helper function to check if the token is expired
  const isTokenExpired = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      console.log("Token expiration time:", decodedToken.exp);
      console.log("Current time:", currentTime);
      return decodedToken.exp < currentTime;
    } catch (error) {
      console.error("Error decoding token: ", error);
      return true;
    }
  };

  console.log(accessToken);

  const {
    isSearchResults,
    isRecentResults,
    isFavoritesResults,
    isTrashResults,
  } = useSelector((state) => state.pageInfo);
  const storeFiles = useSelector((state) => state.files.files);
  const currentItems = useSelector((state) => state.files.currentItems);
  const multipleFileSelect = useSelector(
    (state) => state.files.multipleFileSelect
  );

  const shouldEnableDragAndDrop =
    !isTrashResults &&
    !isFavoritesResults &&
    !isRecentResults &&
    !isSearchResults;

  useEffect(() => {
    handleSort("date");
  }, []);

  useEffect(() => {
    setFilesData([...storeFiles]);
    setFiles([...storeFiles]);
    const { tree, rootFolders } = createFolderTree(
      storeFiles.filter((item) => item.name.includes("/"))
    );
    setFolderTree(tree);
    setRootFolders(rootFolders);
  }, [storeFiles]);

  const preventDefault = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  useEffect(() => {
    document.addEventListener("dragover", preventDefault, false);
    document.addEventListener("drop", preventDefault, false);
    return () => {
      document.removeEventListener("dragover", preventDefault, false);
      document.removeEventListener("drop", preventDefault, false);
    };
  }, []);

  const handleDropDisableUpload = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
  };

  // Check for accessToken on component mount
  useEffect(() => {
    const initializeAccessToken = async () => {
      const tokenFromState = location.state ? location.state.accessToken : null;
      const tokenFromStorage = localStorage.getItem("accessToken");

      let tokenToUse = tokenFromState || tokenFromStorage;

      if (tokenToUse) {
        // Check if token is expired
        if (isTokenExpired(tokenToUse)) {
          console.log("Token is expired. Refreshing...");
          await refreshAccessToken(); // Wait for the token to be refreshed
          tokenToUse = localStorage.getItem("accessToken"); // Get the new token after refreshing
          console.log("REFERSHED");
        }
        setAccessToken(tokenToUse); // Set the updated token
        console.log("Not EXPIRED");
      } else {
        navigate("/login"); // No token, redirect to login
      }
    };

    initializeAccessToken(); // Call the async function

    // Set an interval to refresh the token periodically
    const intervalId = setInterval(async () => {
      if (accessToken && isTokenExpired(accessToken)) {
        console.log("Token is expired. Refreshing...");
        await refreshAccessToken(); // Refresh the token
        const newToken = localStorage.getItem("accessToken"); // Get the new token after refreshing

        // Log the expiration time of the new token
        const decodedNewToken = jwtDecode(newToken);
        console.log("New token expiration time:", decodedNewToken.exp);
        setAccessToken(newToken); // Update the access token in state
      }
    }, 3000000); // 50 minutes in milliseconds (59 * 60 * 1000)

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [location.state, navigate, refreshAccessToken, accessToken]);

  // FETCHING FILES AND FOLDERS

  useEffect(() => {
    // Retrieve the folder path from localStorage or default to "Home"
    const folderPath = localStorage.getItem("folderPath") || "Home";

    console.log("Current folderPath from localStorage:", folderPath);

    if (!accessToken) {
      console.warn("Token not available. Skipping fetch.");
      return;
    }

    // Reset state to avoid stale data issues
    setInitialItems([]);
    dispatch(clearCurrentItems([]));
    setError(null);
    setInitialFileLoading(true);

    // Fetch data for the current folder
    fetchFilesAndFolders(folderPath);
    setSelectedFolder(folderPath);
    setCurrentFolderPath(folderPath === "Home" ? "" : `${folderPath}/`);
  }, [accessToken]);

  const processFolderContents = (data, folderPath, tree, rootFolders) => {
    const files = data.filter((item) => !item.name.includes("/"));
    const folders = data.filter((item) => item.name.includes("/"));

    // If the currentFolderPath is "Home," show root-level items
    if (folderPath === "Home") {
      const initialDisplayItems = [
        ...files.map((file) => ({
          name: file.name,
          isFolder: false,
          path: `/${file.name}`,
          size: file.size,
          createdDate: file.createdDate,
          favorite: file.favorite,
          lastModifiedDate: file.lastModifiedTime,
          owner: file.owner,
        })),
        ...rootFolders.map((folder) => ({
          name: folder,
          isFolder: true,
          path: `/${folder}`,
          owner: folder.owner,
        })),
      ];

      setInitialItems(initialDisplayItems);
      //setCurrentItems(initialDisplayItems);

      const sortedItems = reapplySorting(
        initialDisplayItems,
        sortKey,
        isAscending,
        isTrashResults,
        isRecentResults,
        isFavoritesResults
      );
      dispatch(setCurrentItems(sortedItems));
    } else {
      // Set current folder path and selected folder
      setSelectedFolder(folderPath);
      const newFolderPath = folderPath ? `${folderPath}/` : "";
      setCurrentFolderPath(newFolderPath);

      console.log(`Current Folder Path: ${newFolderPath}`);

      // Filter files that are inside the selected folder
      const folderFiles = data.filter((file) => {
        const relativePath = file.name.substring(folderPath.length + 1);
        return (
          file.name.startsWith(`${folderPath}/`) &&
          relativePath.split("/").length === 1 && // Only files directly inside the folder
          !file.name.endsWith(".emptyfile") // Exclude empty files
        );
      });

      // Traverse the folder tree to find subfolders
      const folderParts = folderPath.split("/");
      let currentFolderTree = tree;

      folderParts.forEach((part) => {
        if (currentFolderTree[part]) {
          currentFolderTree = currentFolderTree[part];
        }
      });

      // Find subfolders in the current folder
      const subfolders = Object.keys(currentFolderTree);
      const folderFolders = subfolders.map((subfolder) => ({
        name: `${folderPath}/${subfolder}`,
        isFolder: true,
        path: `${folderPath}/${subfolder}`,
      }));

      // Update the current items with files and subfolders
      const folderItems = [
        ...folderFiles.map((file) => ({
          name: file.name,
          isFolder: false,
          path: file.name,
          size: file.size,
          createdDate: file.createdDate,
          favorite: file.favorite,
          lastModifiedDate: file.lastModifiedTime,
          owner: file.owner,
        })),
        ...folderFolders,
      ];

      // Set the current items to display files and subfolders
      //setCurrentItems(folderItems);

      const sortedItems = reapplySorting(
        folderItems,
        sortKey,
        isAscending,
        isTrashResults,
        isRecentResults,
        isFavoritesResults
      );
      dispatch(setCurrentItems(sortedItems));
    }
  };

  const fetchFilesData = async () => {
    const headers = {
      "Cache-Control": "no-cache",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      const response = await Axios.get(`${READ_API_URL}/readAll`, { headers });
      const apiResponseData = response.data;
      const data = apiResponseData.data;

      console.log(`---------------RESPONSE DATA----------------------`);
      console.log(`---------------RESPONSE DATA----------------------`);
      console.log(`---------------RESPONSE DATA----------------------`);
      console.log(`---------------RESPONSE DATA----------------------`);
      console.log(apiResponseData);
      console.log(apiResponseData.customerDTO?.prefLanguage);
      // console.log(apiResponseData.customerDTO?.preferences.language);
      console.log(`---------------RESPONSE DATA----------------------`);
      console.log(`---------------RESPONSE DATA----------------------`);
      console.log(`---------------RESPONSE DATA----------------------`);
      console.log(`---------------RESPONSE DATA----------------------`);

      const roles = apiResponseData.customerDTO?.roles || [];
      const language = apiResponseData.customerDTO?.prefLanguage;
      const country = apiResponseData.customerDTO?.prefCountry;
      const timezone = apiResponseData.customerDTO?.prefTimezone;

      dispatch(setRoles(roles));
      dispatch(setLanguage(language));
      dispatch(setCountry(country));
      dispatch(setTimeZone(timezone));
      i18n.changeLanguage(language);

      dispatch(setFilesStore(data));
      const { tree, rootFolders } = createFolderTree(
        data.filter((item) => item.name.includes("/"))
      );
      return { data, tree, rootFolders };
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchFilesAndFolders = async (currentFolderPath = "Home") => {
    console.log(currentFolderPath);
    setInitialFileLoading(true);
    try {
      const { data, tree, rootFolders } = await fetchFilesData();
      processFolderContents(data, currentFolderPath, tree, rootFolders);
      setInitialFileLoading(false);
      setLoading(false);
    } catch (error) {
      setError(error);
      setInitialFileLoading(false);
      setLoading(false);
    }
  };

  const handleFolderClick = (folderPath, event) => {
    console.log("Folder clicked Kamal:", folderPath);
    if (event) {
      event.stopPropagation();
    }
    dispatch(setSearchTerm(""));
    dispatch(
      folderPath.toLowerCase() === "home"
        ? setCurrentPage("home")
        : setCurrentPage("subFolder")
    );

    const normalizedFolderPath = folderPath.startsWith("/")
      ? folderPath.substring(1)
      : folderPath;

    setSelectedFolder(normalizedFolderPath);

    setCurrentFolderPath(
      normalizedFolderPath === "Home" ? "" : `${normalizedFolderPath}/`
    );

    localStorage.setItem("folderPath", normalizedFolderPath);

    const { tree, rootFolders } = createFolderTree(
      filesData.filter((item) => item.name.includes("/"))
    );

    setFolderTree(tree);
    setRootFolders(rootFolders);

    processFolderContents(filesData, normalizedFolderPath, tree, rootFolders);
  };

  const handelRefreshAfterAction = () => {
    const currentFolderPath = localStorage.getItem("folderPath") || "Home";

    fetchFilesAndFolders(currentFolderPath);
  };

  // Sync isAllSelected state with checked files
  useEffect(() => {
    const totalFiles = currentItems.filter((item) => !item.isFolder).length;
    setIsAllSelected(checkedFiles.length === totalFiles && totalFiles > 0);
  }, [checkedFiles, currentItems]);

  // ----------------------------------------------
  useEffect(() => {
    const handleClickOutside = (event) => {
      const container2 = document.querySelector(".container2-body");
      const contextMenuElement = document.querySelector(".context-menu");

      if (
        !container2?.contains(event.target) &&
        !contextMenuElement?.contains(event.target)
      ) {
        handleContextMenuClose();
      }
    };

    document[contextMenu.visible ? "addEventListener" : "removeEventListener"](
      "mousedown",
      handleClickOutside
    );

    // Disable scroll when context menu is open
    if (contextMenu.visible) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [contextMenu.visible]);

  // ----------------------------------------------

  // SORTING

  const extractFileName = (filePath) => {
    return filePath.split("/").pop();
  };

  useEffect(() => {
    const savedSortKey = localStorage.getItem("sortKey") || "name";
    const savedIsAscending =
      JSON.parse(localStorage.getItem("isAscending")) ?? true;
    setSortKey(savedSortKey);
    setIsAscending(savedIsAscending);
    const sortedItems = reapplySorting(
      initialItems,
      savedSortKey,
      savedIsAscending,
      isTrashResults,
      isRecentResults,
      isFavoritesResults
    );
    dispatch(setCurrentItems(sortedItems));
  }, [initialItems]);

  const handleSort = (key) => {
    const newSortOrder = sortKey === key ? !isAscending : true;
    setSortKey(key);
    setIsAscending(newSortOrder); // Persist sorting preferences
    localStorage.setItem("sortKey", key);
    localStorage.setItem("isAscending", JSON.stringify(newSortOrder)); // Reapply sorting
    const sortedItems = reapplySorting(
      currentItems,
      key,
      newSortOrder,
      isTrashResults,
      isRecentResults,
      isFavoritesResults
    );
    dispatch(setCurrentItems(sortedItems));
  };

  const handleKeyDown = (event) => {
    if (isRenameDialogOpen) {
      return; // Prevent further actions if rename popup is active
    }

    if (contextMenu.visible) {
      event.preventDefault();
      // Handle context menu navigation
      if (event.key === "ArrowDown") {
        setContextMenuIndex((prevIndex) =>
          prevIndex < contextMenu.options.length - 1 ? prevIndex + 1 : prevIndex
        );
      } else if (event.key === "ArrowUp") {
        setContextMenuIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : prevIndex
        );
      } else if (event.key === "Enter" && contextMenuIndex >= 0) {
        handleContextMenuOptionClick(
          contextMenu.options[contextMenuIndex],
          multipleFileSelect
        );
      } else if (event.key === "Escape") {
        handleContextMenuClose();
      }
    } else {
      // Handle file navigation with Arrow keys only
      if (event.key === "ArrowDown" || event.key === "ArrowUp") {
        setSelectedIndex((prevIndex) => {
          const newIndex =
            event.key === "ArrowDown"
              ? Math.min(prevIndex + 1, currentItems.length - 1)
              : Math.max(prevIndex - 1, 0);
          selectedIndexRef.current = newIndex;
          return newIndex;
        });

        // Update `multipleFileSelect` only when using Arrow keys
        const selectedItem = currentItems[selectedIndexRef.current];
        if (selectedItem) {
          dispatch(
            setMultipleFileSelect([
              {
                name: selectedItem.name,
                size: selectedItem.size,
                path: selectedItem.path,
                isFolder: selectedItem.isFolder,
                createdDate: selectedItem.createdDate,
              },
            ])
          );
        }
      }

      // Handle Enter key for file/folder interaction
      if (event.key === "Enter") {
        const selectedItem = currentItems[selectedIndexRef.current];
        if (selectedItem && selectedItem.isFolder) {
          handleFolderClick(selectedItem.path);
        } else if (selectedItem && !selectedItem.isFolder) {
          const index = currentItems.findIndex(
            (_item) => _item.name === selectedItem.name
          );
          handleFilePreviewClick(
            selectedItem.name,
            selectedItem.path,
            index,
            currentItems
          );
        }
      }
    }
  };

  useEffect(() => {
    const selectedItem = document.querySelector(".details-Row.selected-file");
    if (selectedItem) {
      const container = document.querySelector(".container2-body");
      const containerTop = container.scrollTop;
      const containerBottom = containerTop + container.clientHeight;
      const itemTop = selectedItem.offsetTop;
      const itemBottom = itemTop + selectedItem.clientHeight;

      console.log({ containerTop, containerBottom, itemTop, itemBottom });

      // Scroll up: if the selected item is above the visible area of the container
      if (itemTop < containerTop) {
        // Add a slight offset to scroll the item a little higher even when at the top
        container.scrollTop = itemTop - 20; // Adjust "20" as needed for the slight offset
      }
      // Scroll down: if the selected item is below the visible area of the container
      else if (itemBottom > containerBottom) {
        container.scrollTop = itemBottom - container.clientHeight; // Scroll to the bottom of the item
      }
      // Ensure the item is always within the visible area when already partially visible
      else if (itemTop >= containerTop && itemBottom <= containerBottom) {
        // Optionally center the selected item within the visible area
        const offset = (container.clientHeight - selectedItem.clientHeight) / 1;
        container.scrollTop = itemTop - offset;
      }
    }
  }, [selectedIndex]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    contextMenu.visible,
    contextMenu.options,
    contextMenuIndex,
    currentItems.length,
  ]);

  useEffect(() => {
    // Reset the selectedIndex to start from the top when currentItems changes
    setSelectedIndex(-1); // or 0 if you'd prefer the navigation to start at the first item
    dispatch(clearMultipleFileSelect());
  }, [currentItems]);

  // Update selected file based on `selectedIndex` change
  useEffect(() => {
    if (selectedIndex >= 0 && selectedIndex < currentItems.length) {
      const selectedFile = currentItems[selectedIndex];
      dispatch(setMultipleFileSelect([selectedFile]));
    }
  }, [selectedIndex]);

  console.log(multipleFileSelect);
  console.log(JSON.stringify(multipleFileSelect));

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [contextMenu, contextMenu.options, contextMenuIndex, currentItems.length]);

  // ------------------------------------

  if (loading) {
    return (
      <div className="spinner-container">
        <RingLoader color="#3498db" className="ring" />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleLogout = async () => {
    dispatch(setSearchTerm(""));
    try {
      await signOut(auth);
      localStorage.removeItem("accessToken"); // Remove token from localStorage
      localStorage.removeItem("folderPath");
      localStorage.removeItem("isAscending");
      localStorage.removeItem("sortKey");
      localStorage.removeItem("sortKey");
      setAccessToken(null);
      navigate("/");
      console.log(`ACCESS TOKEN WHILE LOGOUT: ${accessToken}`);
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  // Folder click handling

  const hardcodedReceivedDate = "2024-10-01";

  const handleUploadButtonClick = () => {
    setIsUploadOverlayOpen(true);
  };

  const handleCloseOverlay = () => {
    setIsUploadOverlayOpen(false);
  };

  const handleFilePreviewClose = () => {
    setIsFilePreviewOverlayOpen(false);
  };

  const handleFilePreviewClick = (fileName, filePath, index, fileList) => {
    setSelectedFile(fileName);
    setSelectedFilePath(filePath);
    setCurrentFileIndex(index);
    dispatch(setCurrentItems(fileList));
    setIsFilePreviewOverlayOpen(true);
  };

  // Close the email overlay after success
  const closeEmailOverLay = (result) => {
    setIsEmailOverlayOpen(false);
    if (result === true) {
      messageApi.open({
        type: "success",
        content: t("Emailsentsuccessfully"),
        duration: 3,
      });
      setCheckedFiles([]);
      setIsAllSelected(false);
    }
    dispatch(clearEmailsList());
  };

  /* HANDEL SINGLE AND MULTIPLE FILE CLICK AND CONTEXT MENU */
  const handelSingleFileClick = (event, file, index) => {
    console.log(file.path);
    if (event.ctrlKey || event.metaKey) {
      handelMultipleFileClick(file);
    } else {
      const selectedFile = {
        name: file.name,
        size: file.size,
        path: file.path,
        isFolder: file.isFolder,
      };

      if (multipleFileSelect.some((item) => item.path === selectedFile.path)) {
        // Deselect if already selected
        const filteredFile = multipleFileSelect.filter(
          (item) => item.path !== selectedFile.path
        );
        dispatch(setMultipleFileSelect(filteredFile));
        setSelectedIndex(-1); // Reset selected index
        selectedIndexRef.current = -1; // Reset selected index ref
      } else {
        dispatch(setMultipleFileSelect([selectedFile]));
        setSelectedIndex(index); // Update selected index
        selectedIndexRef.current = index; // Update ref
      }
    }

    setContextMenu({ visible: false });
  };

  const handelMultipleFileClick = (file) => {
    console.log(file);
    const selectedFile = {
      name: file.name,
      size: file.size,
      path: file.path,
      isFolder: file.isFolder,
    };
    if (multipleFileSelect.some((item) => item.path === selectedFile.path)) {
      // If Already selected remove it
      dispatch(removeSelectedFile(file));
    } else {
      // Add to selected files
      dispatch(addSelectedFile(file));
    }
  };

  const handleContextMenuClose = () => {
    setContextMenu({ visible: false });
  };

  const handleContextMenuOptionClick = (option, selectedFiles) => {
    handleContextMenuClose();
    contextMenuOptionClick(
      dispatch,
      option,
      currentItems,
      selectedFiles,
      accessToken,
      handelRefreshAfterAction,
      messageApi,
      t,
      setIsFilePreviewOverlayOpen,
      setSelectedFilesToEmail,
      setIsEmailOverlayOpen,
      setFileToRename,
      setIsRenameDialogOpen,
      setSelectedFileForNewFolder,
      setIsCreateFolderModalOpen,
      setCopiedPaths,
      setOperationType,
      setCutPaths,
      setIsMessageProcessing,
      isMessageProcessing,
      currentFolderPath,
      storeFiles,
      fetchFilesData,
      isFavoritesResults,
      isRecentResults,
      isSearchResults,
      handleFilePreviewClick,
      setIsDownloading,
      setProgress,
      setDownloadStatus,
      setNumberOfDownloadedFiles,
      toast,
      operationType,
      cutPaths,
      copiedPaths
    );
  };

  const onRenameComplete = (message) => {
    if (message) {
      messageApi.open(message);
      if (message.type === "success") {
        if (isSearchResults) {
          setInitialFileLoading(true);
          fetchFilesData().then(() => {
            dispatch(setSearchRefresh(true));
            setInitialFileLoading(false);
          });
        } else {
          handelRefreshAfterAction();
        }
      }
    }
  };

  // Function to handle modal open
  const openCreateFolderModal = () => {
    setIsCreateFolderModalOpen(true);
  };

  // Function to handle modal close
  const closeCreateFolderModal = () => {
    setIsCreateFolderModalOpen(false);
  };

  const handleContainerRightClick = (event) => {
    event.preventDefault();
    if (
      !event.target.closest(".details-Row") &&
      selectedFolder !== "__Recent__" &&
      selectedFolder !== "__Favorites__" &&
      selectedFolder !== "__Trash__" &&
      isSearchResults === false
    ) {
      const options = [
        ...(copiedPaths.length > 0 || cutPaths.length > 0 ? ["Paste"] : []),
        "Folder",
      ];

      positionContextMenu(event, ".context-menu", setContextMenu, options);
      setContextMenuIndex(-1);
    }
  };

  const handleRightClick = (event, file) => {
    event.preventDefault();
    event.stopPropagation();
    const options = ContextMenuOptionsBuilder(
      file,
      multipleFileSelect,
      dispatch,
      isTrashResults,
      isSearchResults,
      isRecentResults,
      isFavoritesResults,
      copiedPaths,
      cutPaths,
      t
    );
    positionContextMenu(event, ".context-menu", setContextMenu, options);
    setContextMenuIndex(-1);
  };

  const handleFileSearch = (searchResults) => {
    setSelectedIndex(-1);
    selectedIndexRef.current = -1;
    dispatch(setCurrentPage("search")); // Set current page to "search"
    dispatch(setCurrentItems(searchResults));
    dispatch(clearMultipleFileSelect());
  };

  const RecentFilesResults = (recent_result) => {
    setSelectedIndex(-1);
    selectedIndexRef.current = -1;
    dispatch(setCurrentPage("recent")); // Set current page to "recent"
    dispatch(setCurrentItems(recent_result));
    dispatch(clearMultipleFileSelect());
    setSelectedFolder("__Recent__");
  };

  const FavoriteFilesResults = (favorite_result) => {
    setSelectedIndex(-1);
    selectedIndexRef.current = -1;
    dispatch(setCurrentPage("favorites")); // Set current page to "favorites"
    dispatch(setCurrentItems(favorite_result));
    dispatch(clearMultipleFileSelect());
    setSelectedFolder("__Favorites__");
  };

  const TrashFilesResults = (trash_result) => {
    setSelectedIndex(-1);
    selectedIndexRef.current = -1;
    dispatch(setCurrentPage("trash")); // Set current page to "trash"
    dispatch(setCurrentItems(trash_result));
    dispatch(clearMultipleFileSelect());
    setSelectedFolder("__Trash__");
  };

  const handleDrop = async (e) => {
    await handleDropUtil(
      e,
      setDragActive,
      setProgress,
      setDownloadStatus,
      setIsUploading,
      setFolderFiles,
      accessToken,
      userDetail,
      handelRefreshAfterAction,
      toast,
      allowedExtensions,
      currentFolderPath,
      traverseDirectory,
      FilesandFolderDropService
    );
  };

  // Recursive function to traverse directory structure and handle async files
  const traverseDirectory = (dirEntry, filesList, currentPath) => {
    return new Promise((resolve, reject) => {
      const dirReader = dirEntry.createReader();
      const entries = [];

      const readEntries = () => {
        dirReader.readEntries((results) => {
          if (!results.length) {
            if (entries.length === 0) {
              // {newFolderName: 'DOOOOOOOO'}
              const newFolderPATH = currentPath.slice(0, -1);

              const myNames = [{ newFolderName: newFolderPATH }];
              CreateFolderService(myNames, accessToken);
            }

            // All entries are read
            Promise.all(
              entries.map((entry) =>
                processEntry(entry, filesList, currentPath)
              )
            )
              .then(resolve)
              .catch(reject);
          } else {
            entries.push(...results);
            readEntries();
          }
        });
      };

      const processEntry = (entry, filesList, currentPath) => {
        return new Promise((resolve) => {
          if (entry.isFile) {
            entry.file((file) => {
              filesList.push({
                file,
                path: currentPath, // Folder path only
                name: file.name, // File name only
              });
              console.log("File found:", currentPath, file.name);
              resolve();
            });
          } else if (entry.isDirectory) {
            console.log("Folder found:", currentPath + entry.name);
            traverseDirectory(
              entry,
              filesList,
              `${currentPath}${entry.name}/`
            ).then(resolve);
          }
        });
      };

      readEntries();
    });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragActive(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragActive(false);
  };

  console.log(currentFolderPath);

  const handlePathChange = (newPath) => {
    handleFolderClick(newPath === "" ? "Home" : newPath);
  };

  // console.log(currentFolderPath);

  const handleDrag = (e) => {
    const newWidth = e.clientX; // Use cursor position to calculate width
    if (newWidth >= 200 && newWidth < 350) {
      setSidebarWidth(newWidth); // Restrict sidebar width to min and max values
    }
  };

  const logoClickHandeler = () => {
    setSelectedFolder("Home");
    setCurrentFolderPath("");
    fetchFilesAndFolders("Home");
  };

  const refreshIconHandler = (e) => {
    const path = localStorage.getItem("folderPath") || "Home";
    if (isSearchResults) {
      setInitialFileLoading(true);
      fetchFilesData().then(() => {
        dispatch(setSearchRefresh(true));
        setInitialFileLoading(false);
      });
      return;
    }
    if (
      selectedFolder !== "__Recent__" &&
      selectedFolder !== "__Favorites__" &&
      selectedFolder !== "__Trash__"
    ) {
      localStorage.setItem("sortKey", "date");
      localStorage.setItem("isAscending", "false");
      fetchFilesAndFolders(path);
    }

    if (selectedFolder === "__Recent__") {
      setSideBarRefreshName({
        name: "__Recent__",
        event: e,
      });
    }

    if (selectedFolder === "__Favorites__") {
      setSideBarRefreshName({
        name: "__Favorites__",
        event: e,
      });
    }

    if (selectedFolder === "__Trash__") {
      setSideBarRefreshName({
        name: "__Trash__",
        event: e,
      });
    }
  };

  // Replace the existing functions with the imported ones
  const handleIndividualDragStartWrapper = (event, item) => {
    handleIndividualDragStart(event, item, multipleFileSelect, setIsDragging);
  };

  const handleIndividualDropWrapper = (event, targetItem) => {
    handleIndividualDrop(
      dispatch,
      event,
      targetItem,
      accessToken,
      handleDrop,
      handleDragOver,
      handleDragLeave,
      setHoveringItems,
      handelRefreshAfterAction
    );
  };

  const handleIndividualDragOverWrapper = (event, item, index) => {
    handleIndividualDragOver(event, item, index, isDragging, setHoveringItems);
  };

  const handleIndividualDragLeaveWrapper = () => {
    handleIndividualDragLeave(setHoveringItems);
  };

  const handleIndividualDragEndWrapper = () => {
    handleIndividualDragEnd(setIsDragging);
  };

  const getHeading = () => {
    if (isSearchResults) {
      return (
        <p className="other-heading">
          {t("Search")}&nbsp;{t("Results")}
        </p>
      );
    } else if (isRecentResults) {
      return <p className="other-heading">{t("Recent")}</p>;
    } else if (isFavoritesResults) {
      return <p className="other-heading">{t("Favorites")}</p>;
    } else if (isTrashResults) {
      return <p className="other-heading">{t("Trash")}</p>;
    } else {
      return (
        <BreadcrumbComponent
          path={currentFolderPath}
          onPathChange={handlePathChange}
        />
      );
    }
  };

  return (
    <>
      {initialFileLoading && (
        <div className="spinner-container1 skip-global-styles">
          <CircularProgress style={{ color: "blue" }} />
        </div>
      )}
      {isDownloading && (
        <ProgressIndicator
          status={downloadStatus}
          progress={progress}
          number={numberOfDownloadedFiles}
        />
      )}

      {isUploading && (
        <ProgressIndicator
          status={downloadStatus}
          progress={progress}
          number={t("UploadingMsg")}
        />
      )}

      {messageContextHolder}
      <ToastContainer theme="dark" />
      {isUploadOverlayOpen && (
        <CommonOverlay
          isOpen={isUploadOverlayOpen}
          onClose={handleCloseOverlay}
          size="medium"
          title="Upload"
        >
          <UploadOverlay
            isOpen={isUploadOverlayOpen}
            onClose={handleCloseOverlay}
            folderPath={currentFolderPath} //Pass the selected folderpath
            accessToken={accessToken}
            handleRefreshAfterUpload={handelRefreshAfterAction}
            selectedFolder={selectedFolder}
            setSelectedFolder={setSelectedFolder}
            setCurrentFolderPath={setCurrentFolderPath}
          />
        </CommonOverlay>
      )}

      {isEmailOverlayOpen && (
        <CommonOverlay
          isOpen={isEmailOverlayOpen}
          onClose={closeEmailOverLay}
          size="medium"
          title="Email"
        >
          <EmailScreen
            closeOverlay={closeEmailOverLay}
            accessToken={accessToken}
            selectedFilesToEmail={selectedFilesToEmail}
          />
        </CommonOverlay>
      )}
      <div className="global-container">
        {userDetail && (
          <Header1
            email={userDetail.email}
            onLogout={handleLogout}
            name={userDetail.name}
            photoURL={userDetail.profilePicture}
            files={files}
            handleFileSearch={handleFileSearch}
            accessToken={accessToken}
            handelLogoClick={logoClickHandeler}
            fetchFilesData={fetchFilesData}
          />
        )}
        <div
          id="homeBody"
          className="home-body1"
          style={{ gridTemplateColumns: `${sidebarWidth}px auto` }}
        >
          <div className="resizable-sidebar">
            <SideBar1
              folderTree={folderTree}
              onFolderClick={handleFolderClick}
              selectedFolder={selectedFolder}
              onUploadButtonClick={handleUploadButtonClick}
              ContextCloseHandeler={handleContextMenuClose}
              expandedFolders={expandedFolders} // Track expanded folders
              setExpandedFolders={setExpandedFolders} // Update expanded folders
              onCreateFolderClick={openCreateFolderModal}
              RecentFilesResults={RecentFilesResults}
              FavoriteFilesResults={FavoriteFilesResults}
              TrashFilesResults={TrashFilesResults}
              accessToken={accessToken}
              SideBarWidth={sidebarWidth}
              sideBarRefreshName={sideBarRefreshName}
              setInitialFileLoading={setInitialFileLoading}
            />

            <div
              id="dragContainer"
              className="drag-handle"
              onMouseDown={(e) => {
                // Add hover effect manually when dragging starts
                e.target.classList.add("drag-handle-dragging");
                document.addEventListener("mousemove", handleDrag);
                document.addEventListener("mouseup", () => {
                  document.removeEventListener("mousemove", handleDrag);
                  // Remove hover effect when dragging ends
                  e.target.classList.remove("drag-handle-dragging");
                  // Reset cursor
                  document.body.style.cursor = "auto";
                });
                // Lock the cursor to `ew-resize` when mouse is down
                document.body.style.cursor = "ew-resize";
              }}
            />
          </div>

          <div id="documentContainer" className="container2">
            <div id="documentContainerHeading" className="container2-options">
              <div id="documentContainerHeading" className="container2-options">
                {getHeading()}
              </div>
            </div>
            <div className="container2-options2" style={{ height: "50px" }}>
              <DocumentTopActionIcons
                t={t}
                handleContextMenuOptionClick={handleContextMenuOptionClick}
                handleUploadButtonClick={handleUploadButtonClick}
                openCreateFolderModal={openCreateFolderModal}
                cutPaths={cutPaths}
                copiedPaths={copiedPaths}
                refreshIconHandler={refreshIconHandler}
              />
              {isTrashResults && (
                <div className="trash-info-container">
                  <span className="trash-info">{t("TrashWarningInfo")}</span>
                </div>
              )}
            </div>
            <div
              onDrop={
                shouldEnableDragAndDrop
                  ? isDragging === false
                    ? handleDrop
                    : handleDropDisableUpload
                  : handleDropDisableUpload
              }
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              id="documentTable"
              className={`container2-body ${
                dragActive && isDragging === false ? `drag-active` : ``
              }`}
              onContextMenu={(event) => {
                handleContainerRightClick(event);
              }}
            >
              {!shouldEnableDragAndDrop && dragActive && (
                <div class="overlayUploadDragDisabled">
                  <div class="overlayUploadDragDisabledMessage">
                    Drag & drop not allowed in Recents, Trash & Favorites.
                  </div>
                </div>
              )}

              <div
                id="documentTableHeading"
                className={`details-Row title ${
                  dragActive ? `drag-active1` : ``
                }`}
              >
                <SortableHeader
                  title={t("Name")}
                  onSort={() => handleSort("name")}
                  hoverText={t("sortByasc&desc")}
                  hoverSpan="10px"
                  // icon={<InsertDriveFileIcon />}
                  // marginLeft="38px"
                  marginLeft="3px"
                  dragActive={dragActive}
                  headingWidth="38%"
                  minWidth="220px"
                />

                <SortableHeader
                  title={t("Owner")}
                  onSort={() => handleSort("owner")}
                  hoverText={t("sortByasc&desc")}
                  hoverSpan="10px"
                  // icon={<InsertDriveFileIcon />}
                  // marginLeft="38px"
                  marginLeft="3px"
                  dragActive={dragActive}
                  headingWidth="13%"
                  minWidth="180px"
                />
                <SortableHeader
                  title={
                    isTrashResults
                      ? t("DeletedDate")
                      : isRecentResults
                      ? t("LastActivityDate")
                      : t("LastModifiedDate")
                  }
                  onSort={() => handleSort("date")}
                  hoverText={t("sortbydate")}
                  hoverSpan="50px"
                  marginLeft="10px"
                  dragActive={dragActive}
                  headingWidth="13%"
                  minWidth="180px"
                />
                <SortableHeader
                  title={t("Size")}
                  onSort={() => handleSort("size")}
                  hoverText={t("sortbysize")}
                  marginLeft="10px"
                  hoverSpan="20px"
                  dragActive={dragActive}
                  headingWidth="8%"
                  minWidth="110px"
                />
                {(isSearchResults ||
                  isRecentResults ||
                  isFavoritesResults ||
                  isTrashResults) && (
                  <SortableHeader
                    title={t("Location")}
                    marginLeft="10px"
                    hoverSpan="30px"
                    headingWidth="28%"
                    dragActive={dragActive}
                    hoverText={t("Location")}
                    minWidth="110px"
                  />
                )}
              </div>
              {currentItems.length === 0 ? (
                <EmptyFolderImage dragActive={dragActive} />
              ) : (
                <></>
              )}

              <DocumentGrid
                currentItems={currentItems}
                dragActive={dragActive}
                handelSingleFileClick={handelSingleFileClick}
                handleRightClick={handleRightClick}
                handleFolderClick={handleFolderClick}
                handleFilePreviewClick={handleFilePreviewClick}
                hoveringItems={hoveringItems}
                handleIndividualDragStart={handleIndividualDragStartWrapper}
                handleIndividualDragOver={handleIndividualDragOverWrapper}
                handleIndividualDrop={handleIndividualDropWrapper}
                handleIndividualDragLeave={handleIndividualDragLeaveWrapper}
                handleIndividualDragEnd={handleIndividualDragEndWrapper}
                setHoveringItems={setHoveringItems}
                ownerName={userDetail?.name} // Pass the owner name
                ownerLogo={userDetail?.profilePicture} // Pass the owner logo
                ownerEmail={userDetail?.email} // Pass the owner email
              />

              <CreateFolderModal
                isOpen={isCreateFolderModalOpen}
                onClose={closeCreateFolderModal}
                currentFolderPath={currentFolderPath} // Pass folder path if needed
                selectedFolder={selectedFolder}
                selectedFileForNewFolder={selectedFileForNewFolder}
                accessToken={accessToken}
                messageApi={messageApi}
                setSearchTerm={setSearchTerm}
                handelRefreshAfterAction={handelRefreshAfterAction}
                setSelectedFolder={setSelectedFolder}
                setSelectedFileForNewFolder={setSelectedFileForNewFolder}
                setCurrentFolderPath={setCurrentFolderPath}
              />

              {/* RenameDialog component */}
              <RenameDialog
                accessToken={accessToken}
                open={isRenameDialogOpen}
                onClose={() => setIsRenameDialogOpen(false)}
                fileName={
                  fileToRename
                    ? fileToRename.isFolder
                      ? fileToRename.name // For folders, pass the full name (no slash at the end)
                      : fileToRename.name.substring(
                          0,
                          fileToRename.name.lastIndexOf(".")
                        ) // For files, pass name without extension
                    : ""
                }
                fileToRename={fileToRename}
                setInitialFileLoading={setInitialFileLoading}
                messageApi={messageApi}
                onRenameComplete={onRenameComplete}
              />
              {isFilePreviewOverlayOpen && (
                <CommonOverlay
                  isOpen={isFilePreviewOverlayOpen}
                  onClose={handleFilePreviewClose}
                  size="medium"
                  title=""
                >
                  <FilePreview
                    fileName={selectedFile}
                    filePath={selectedFilePath}
                    fileIndex={currentFileIndex}
                    allFiles={currentItems.filter(
                      (item) => item.isFolder === false || item.folder === false
                    )}
                    onClose={handleFilePreviewClose}
                    accessToken={accessToken}
                  />
                </CommonOverlay>
              )}

              <ContextMenu
                contextMenu={contextMenu}
                contextMenuIndex={contextMenuIndex}
                handleContextMenuOptionClick={handleContextMenuOptionClick}
                t={t}
                isCreateFolderModalOpen={isCreateFolderModalOpen}
                isRenameDialogOpen={isRenameDialogOpen}
                isFilePreviewOverlayOpen={isFilePreviewOverlayOpen}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
