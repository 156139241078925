export const handleDropUtil = async (
    e,
    setDragActive,
    setProgress,
    setDownloadStatus,
    setIsUploading,
    setFolderFiles,
    accessToken,
    userDetail,
    handelRefreshAfterAction,
    toast,
    allowedExtensions,
    currentFolderPath,
    traverseDirectory,
    FilesandFolderDropService
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  
    setProgress(0);
    setDownloadStatus("Uploading Files...");
    setIsUploading(true);
  
    const items = e.dataTransfer.items;
    const droppedFiles = [];
    const directoryPromises = [];
    const invalidExtensions = new Set();
  
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const entry = item.webkitGetAsEntry && item.webkitGetAsEntry();
  
      if (entry) {
        if (entry.isFile) {
          const file = item.getAsFile();
          if (file) {
            const fileExtension = file.name.split(".").pop().toLowerCase();
            if (allowedExtensions.includes(fileExtension)) {
              droppedFiles.push({
                file,
                name: file.name,
                size: file.size,
                path: currentFolderPath,
              });
            } else {
              invalidExtensions.add(`.${fileExtension}`);
            }
          }
        } else if (entry.isDirectory) {
          const rootFolderName = entry.name;
          directoryPromises.push(
            traverseDirectory(
              entry,
              droppedFiles,
              `${currentFolderPath}${rootFolderName}/`
            )
          );
        }
      }
    }
  
    await Promise.all(directoryPromises);
  
    const hasValidFiles = droppedFiles.length > 0;
  
    if (items.length === 1 && !hasValidFiles) {
      const invalidExtensionsList = Array.from(invalidExtensions).join(", ");
      toast.error(`${invalidExtensionsList} is not valid`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        style: { color: "green", backgroundColor: "#f4f4f4" },
      });
  
      setIsUploading(false);
      return;
    }
  
    if (!hasValidFiles) {
      const invalidExtensionsList = Array.from(invalidExtensions).join(", ");
      toast.error(`${invalidExtensionsList} is not valid`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        style: { color: "green", backgroundColor: "#f4f4f4" },
      });
  
      setIsUploading(false);
      return;
    }
  
    setFolderFiles(droppedFiles);
    console.log("Files and folders with path:", droppedFiles);
  
    let simulatedProgress = 0;
  
    const totalFiles = droppedFiles.length;
  
    const progressInterval = setInterval(() => {
      simulatedProgress += totalFiles === 1 ? 20 : 10;
      if (simulatedProgress < 100) {
        setProgress(simulatedProgress);
      }
    }, 200);
  
    try {
      const responses = await FilesandFolderDropService(
        droppedFiles,
        accessToken,
        userDetail
      );
      console.log("Service response:", responses);
      clearInterval(progressInterval);
  
      const allSuccess = responses.every((res) => res && res.success);
  
      if (allSuccess) {
        setProgress(100);
        setDownloadStatus("Upload Completed");
        handelRefreshAfterAction();
  
        if (invalidExtensions.size > 0) {
          const invalidExtensionsList =
            Array.from(invalidExtensions).join(", ");
          toast.success(
            `Files uploaded successfully, ${invalidExtensionsList} is not valid`,
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              style: { color: "green", backgroundColor: "#f4f4f4" },
            }
          );
        } else {
          toast.success("Files uploaded successfully!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            style: { color: "green", backgroundColor: "#f4f4f4" },
          });
        }
      } else {
        setProgress(100);
        setDownloadStatus("Some Files failed to upload");
        toast.error("File upload failed. Please try again.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          style: { color: "red", backgroundColor: "#f4f4f4" },
        });
      }
    } catch (error) {
      clearInterval(progressInterval);
      setProgress(0);
      setDownloadStatus("Upload Failed");
  
      console.error("Error uploading files:", error);
      toast.error("File upload failed. Please try again.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        style: { color: "red", backgroundColor: "#f4f4f4" },
      });
    } finally {
      setIsUploading(false);
    }
  };