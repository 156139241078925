import { useEffect, useState } from "react";
import "../HeaderComponents/MainHeader.css";
import LogoSection from "./LogoSection";
import SearchSection from "./SearchSection";
import IconsSection from "./IconsSection";
import ProfileSection from "./ProfileSection";
import { useSelector } from "react-redux";
import axios from "axios";
import { getAllPreferenceOptionService } from "../../services/getAllPreferenceOptionService";

export default function MainHeader({
  handelLogoClick,
  files,
  handleFileSearch,
  handleFeedback,
  name,
  onLogout,
  email,
  photoURL,
  accessToken,
  fetchFilesData,
}) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [listOfPreferenceData, setListOfPreferenceData] = useState(null);

  function toggleDrawer() {
    setIsDrawerOpen((prev) => !prev);
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await getAllPreferenceOptionService(accessToken);

      // console.log(response.data);

      if (response.status === 200) {
        setListOfPreferenceData(response.data);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <header id="mainHeader" className="header1">
        <LogoSection handelLogoClick={handelLogoClick} />
        <SearchSection files={files} handleFileSearch={handleFileSearch} />
        <IconsSection
          handleFeedback={handleFeedback}
          photoURL={photoURL}
          toggleDrawer={toggleDrawer}
        />
      </header>
      <ProfileSection
        name={name}
        onLogout={onLogout}
        photoURL={photoURL}
        email={email}
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        accessToken={accessToken}
        fetchFilesData={fetchFilesData}
        listOfPreferenceData={listOfPreferenceData}
      />
      {isDrawerOpen && (
        <div className="drawer-overlay" onClick={toggleDrawer}></div>
      )}
    </>
  );
}
