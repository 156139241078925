import { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";
import "../newComponent/FolderModal.css";
import CreateFolderService from "../services/createFolderService";
import {
  setCurrentItems,
  clearMultipleFileSelect,
} from "../store/reducers/filesReducer";
import { setCurrentPage } from "../store/reducers/pageInfoReducer";

function CreateFolderModal({  isOpen, onClose, currentFolderPath, 
  selectedFolder, selectedFileForNewFolder, accessToken, messageApi, 
  setSearchTerm, handelRefreshAfterAction, setSelectedFolder, 
  setCurrentFolderPath, setSelectedFileForNewFolder 
}) {
  const { t } = useTranslation();
  const [folderName, setFolderName] = useState("");
  const [folderErrMsg, setFolderErrMsg] = useState("");

  const inputRef = useRef(null); // Reference for the input element
  const currentItems = useSelector((state) =>state.files.currentItems);
  const dispatch = useDispatch();
   const { isSearchResults } = useSelector((state) => state.pageInfo);
  // Focus the input field when the modal opens
  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);
  useEffect(() => {
    if(folderName.length > 0)
    setFolderErrMsg("");
  
  }, [folderName]);

  const handleSubmit = () => {
    if (folderName.trim() === "") {
      setFolderErrMsg(t("EmptyFolderName"));
      return false;
    }
    else {
      setFolderErrMsg("");
    }
    handleCreateFolderSubmit(folderName);
    setFolderName("");
    onClose();
  };

  const handelKeyDown = (event) => {
    if (event.key === "Enter") {
      if (isOpen) {
        event.preventDefault();
        event.stopPropagation();
        handleSubmit();
      }
    } else if (event.key === "Escape") {
      onClose();
    }
  };

  if (!isOpen) return null;

  const handelChange = (e) => {
    const inputValue = e.target.value;

    if (!inputValue.includes("/")) {
      setFolderName(inputValue);
    }
  };

  const handleCreateFolderSubmit = async (folderName) => {
    console.log(folderName);

    if (
      selectedFolder === "__Recent__" ||
      selectedFolder === "__Favorites__" ||
      selectedFolder === "__Trash__"
    ) {
      setCurrentFolderPath("");
    }

    const trimmedCurrentPath = currentFolderPath.replace(/\/+$/, ""); // Remove trailing slashes
    let newFolderName;

    let myName;

    console.log("Selected File:", selectedFileForNewFolder);

    if (
      Array.isArray(selectedFileForNewFolder) &&
      selectedFileForNewFolder[0]?.isFolder
    ) {
      const selectedFile = selectedFileForNewFolder[0]; // Access the first object in the array
      myName = `${selectedFile.path.replace(/^\/+/, "")}/${folderName}`;
      newFolderName = myName;
      console.log("New Folder Name from Selected File:", myName);
      setSelectedFileForNewFolder([]);
      dispatch(clearMultipleFileSelect());
    } else {
      if (
        trimmedCurrentPath === "" ||
        trimmedCurrentPath === "Home" ||
        selectedFolder === "__Recent__" ||
        selectedFolder === "__Favorites__" ||
        selectedFolder === "__Trash__"
      ) {
        newFolderName = folderName; // Root or Home folder
      } else {
        newFolderName = `${trimmedCurrentPath}/${folderName}`; // Append folder name to current path
      }
    }

    console.log(`Creating folder with name: ${newFolderName}`);

    const folderData = [{ newFolderName }];

    try {
      const createFolderResult = await CreateFolderService(
        folderData,
        accessToken
      );

      if (createFolderResult) {
        // Update the current items with the new folder

        const updatedItems = [
          ...currentItems,
          { name: newFolderName, isFolder: true, path: newFolderName }, // Ensure name and path are correctly set
        ];
        dispatch(setCurrentItems(updatedItems));

        messageApi.open({
          type: "success",
          content: t("FolderCreateSuccessMsg"),
          duration: 3,
        });

        if (
          selectedFolder === "__Favorites__" ||
          selectedFolder === "__Recent__" ||
          selectedFolder === "__Trash__" ||
          isSearchResults === true
        ) {
          setSelectedFolder("Home");
          localStorage.setItem("folderPath", "Home");
          dispatch(setCurrentPage("home"));
          dispatch(setSearchTerm(""));
        }

        handelRefreshAfterAction();
      } else {
        messageApi.open({
          type: "error",
          content: "Folder creation failed. Please try again!",
          duration: 3,
        });
      }
    } catch (error) {
      console.error(error);
      messageApi.open({
        type: "error",
        content: "An error occurred while creating the folder!",
        duration: 3,
      });
    }
  };

  return (
    <div className="modal-overlay">
      <div id="folderModal" className="modal-content">
        <h3>{t('FolderName')}</h3>
        <input
        id="folderName"
          ref={inputRef}
          type="text"
          placeholder={t("EnterFolderName")}
          value={folderName}
          // onChange={(e) => setFolderName(e.target.value)}
          onChange={handelChange}
          onKeyDown={handelKeyDown}
        />
        {folderErrMsg && (
          <div className="error-msg">
          {folderErrMsg}
          </div>)}

        <div className="modal-buttons">
          <button id="folderCancel" className="cancel-button" onClick={onClose}>
            {t('Cancel')}
          </button>
          <button id="folderOk" className="ok-button" onClick={handleSubmit}>
            {t('OK')}
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreateFolderModal;
