import React, { useState, useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import "../components/EmailTagsInput.css";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {validateEmail} from '../utils/emailValidationUtils';
import {
    addEmailToList,
    removeEmailFromList,
    setEmailValidationError,
    setClearEmailValidation,
} from '../store/reducers/emailFilesReducer';

const EmailTagsInput = React.forwardRef(({ placeholder, tags, setTags, maxCount, required, field }, ref) => {
    const { t } = useTranslation();
    const [input, setInput] = useState('');
    const [emailError, setEmailError] = useState('');
    const dispatch = useDispatch();
    const sToEmails = useSelector((state) => state.emailFiles.toEmails);

    const handleInputChange = (e) => {
        setInput(e.target.value.trim());
    };
    

    const handleInputKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ',' || e.key === ';') {
            e.preventDefault();
            e.stopPropagation();
            validateAddEmail();
        }
    };

    const handleEmailPaste = (e) => {
        e.preventDefault();
        const pastedText = e.clipboardData.getData('Text');
        const emailsArray = pastedText.split(/[\s,;]+/)
            .map(email => email.trim().toLowerCase()).filter(email => email.length > 0);
        validateAddMultipleEmails(Array.from(new Set([...emailsArray])));
    };

    const validateAddEmail = () => {
        if (tags.length >= maxCount && input.trim().length > 0) {
            setEmailError(t(`Maximum ${maxCount} email addresses allowed`));
            setTimeout(() => {
                setInput('');
                setEmailError('');
            }, 3000);
            return;
        }
        if (validateEmail(input)) {
            setEmailError('');
            dispatch(addEmailToList({ field, email: input }));
            const newEmail = input.toLowerCase().trim();
            if (!tags.some(e => e === newEmail)) {
                setTags([...tags, newEmail]);
            }
            setInput('');
        } else {
            if (input.trim().length > 0) {
                setEmailError(t('validemailaddress'));
                dispatch(setEmailValidationError());
            } else if (tags.length === 0 && required) {
                console.log('tags.length0', tags);
                setEmailError(t('oneemailaddress'));
                dispatch(setEmailValidationError());
            } else {
                setEmailError('');
                dispatch(setClearEmailValidation());
            }
        }
    };

    const validateAddMultipleEmails = (emailsArray) => {
        let validEmails = [];
        let invalidEmails = [];
        emailsArray.forEach(email => {
            if (validateEmail(email)) {
                validEmails.push(email.toLowerCase());
            } else {
                invalidEmails.push(email);
            }
        });

        if (tags.length + validEmails.length > maxCount) {
            setEmailError(t(`Maximum ${maxCount} email addresses allowed`));
            setInput('');
            setTimeout(() => setEmailError(''), 3000);
            return;
        }

        if (validEmails.length > 0) {
            validEmails.forEach(email => dispatch(addEmailToList({ field, email })));
            setTags([...tags, ...validEmails]);
        }

        if (invalidEmails.length > 0) {
            setInput(invalidEmails.join(', '));
            setEmailError(t('validemailaddress'));
            dispatch(setEmailValidationError());
        } else {
            setEmailError('');
            setInput('');
            dispatch(setClearEmailValidation());
        }
    };

    const removeEmail = (index) => {
        const emailToRemove = tags[index];
        dispatch(removeEmailFromList({ field, email: emailToRemove }));
        const updatedTags = tags.filter((_, i) => i !== index);
        setTags(updatedTags);
    };

    useEffect(() => {
        if (ref && ref.current) {
            ref.current.focus();
        }
    }, [ref]);

    useEffect(() => {
        if (tags.length === 0 && required) {
            console.log('tags.length2', tags);
            setEmailError(t('oneemailaddress'));
            dispatch(setEmailValidationError());
        }
    }, [tags, required]);

    return (
        <>
            <div className="email-tags">
                {tags.map((email, index) => (
                    <div key={index} className="email-tag">
                        {email}
                        <AiOutlineClose onClick={() => removeEmail(index)} />
                    </div>
                ))}
                <input
                    type="text"
                    ref={ref}
                    onBlur={validateAddEmail}
                    value={input}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    onPaste={handleEmailPaste}
                    className="email-tag-input"
                    placeholder={placeholder}
                />
            </div>
            <p className="email-error-message error-message">{emailError}</p>
        </>
    );
});

export default EmailTagsInput;