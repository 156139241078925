import React from 'react';
import './UserInitials.Module.css';

const UserInitials = ({ name }) => {
    const getInitials = (name) => {
        let initials = '';
        /*const nameParts = name.split(' ');
        if (nameParts.length === 1) {
            initials = nameParts[0].charAt(0).toUpperCase();
        } else if (nameParts.length > 1) {
            initials = (nameParts[0].charAt(0) + nameParts[nameParts.length - 1].charAt(0)).toUpperCase();
        }*/
        initials = name.length > 1 ? name.charAt(0).toUpperCase() : "";
        return initials;
    };

    const initials = getInitials(name);

    return (
        <div className="containerStyle">
            {initials}
        </div>
    );
};

export default UserInitials;