import { useEffect, useState } from "react";
import "../Business_Settings/BusinessProfile.css";

import EditIcon from "@mui/icons-material/Edit";

import { useTranslation } from "react-i18next";
import { COMPANY_LOGO_SQUARE } from "../APP_LOGO/app_logo";

const BusinessData = {
  businessName: "Ez-Byte",
  profilePicture: COMPANY_LOGO_SQUARE,
  businessNumber: "(+1) 54521462",
  businessEmail: "ezbyte@gmail.com",
  ein: "45612",
  businessAddress:
    "7th street kulalampur malaysia, park street 7th street kulalampur malaysia, park street",
};

export default function BusinessProfile({ CloseBusinessSettingsHandler }) {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState("business-profile");

  const [editableFields, setEditableFields] = useState({
    businessName: false,
    businessEmail: false,
    businessNumber: false,
    ein: false,
    businessAddress: false,
  });

  const [formData, setFormData] = useState({
    businessName: BusinessData.businessName,
    businessEmail: BusinessData.businessEmail,
    businessNumber: BusinessData.businessNumber,
    ein: BusinessData.ein,
    businessAddress: BusinessData.businessAddress,
  });

  useEffect(() => {
    // Apply styles or trigger animations after overlay is rendered
    const line = document.querySelector(".vertical-line");

    if (line) {
      setTimeout(() => {
        line.style.opacity = 1; // Set opacity after 0.5 seconds
      }, 300);
    }
  }, []);

  const handleChange = (e, field) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };

  const handleSave = (field) => {
    setEditableFields((prev) => ({
      ...prev,
      [field]: false,
    }));
    // Update data in the state or database here if needed
    BusinessData[field] = formData[field]; // This will update the data object

    setEditableFields({
      businessName: false,
      businessEmail: false,
      businessNumber: false,
      ein: false,
      businessAddress: false,
    });
  };

  const handleEdit = (field) => {
    setEditableFields({
      businessName: field === "businessName",
      businessEmail: field === "businessEmail",
      businessNumber: field === "businessNumber",
      businessAddress: field === "businessAddress",
      ein: field === "ein",
    });
  };

  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
  };

  const handleOverlayClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      className="business-overlay-container"
      onClick={CloseBusinessSettingsHandler}
    >
      <div className="business-overlay-content" onClick={handleOverlayClick}>
        <h3 className="business-title">{t("BusinessProfile")}</h3>
        <div className="business-overall-container">
          <div className="left-container">
            <p
              className={`business-information ${
                selectedTab === "business-profile" ? "tab-selected" : ""
              }`}
              onClick={() => handleTabSelect("business-profile")}
            >
              {t("Profile")}
            </p>
            <p
              className={`business-information ${
                selectedTab === "update-logo" ? "tab-selected" : ""
              }`}
              onClick={() => handleTabSelect("update-logo")}
            >
              {t("Logo")}
            </p>
            <p
              className={`business-information ${
                selectedTab === "update-info" ? "tab-selected" : ""
              }`}
              onClick={() => handleTabSelect("update-info")}
            >
              {t("UpdateInfo")}
            </p>
            <p
              className={`business-information ${
                selectedTab === "delete-info" ? "tab-selected" : ""
              }`}
              onClick={() => handleTabSelect("delete-info")}
            >
              {t("DeleteInfo")}
            </p>
          </div>

          <div className="vertical-line"></div>

          <div className="right-container">
            {selectedTab === "business-profile" && (
              <div className="business-details">
                <div className="profile-container">
                  <img
                    src={COMPANY_LOGO_SQUARE}
                    alt=""
                    className="business-profile-logo"
                  />
                </div>

                <div className="business-detail-item">
                  <span className="key">{t("BusinessName")}:</span>
                  <span className="value">{BusinessData.businessName}</span>
                </div>
                <div className="business-detail-item">
                  <span className="key">{t("BusinessEmail")}:</span>
                  <span className="value">{BusinessData.businessEmail}</span>
                </div>
                <div className="business-detail-item">
                  <span className="key">{t("Primarynumber")}:</span>
                  <span className="value">{BusinessData.businessNumber}</span>
                </div>
                <div className="business-detail-item">
                  <span className="key">{t("Ein")}:</span>
                  <span className="value">{BusinessData.ein}</span>
                </div>
                <div className="business-detail-item">
                  <span className="key">
                    {" "}
                    {t("Addressregisterasperstate")}:
                  </span>
                  <span className="value">{BusinessData.businessAddress}</span>
                </div>
              </div>
            )}

            {selectedTab === "update-info" && (
              <div className="business-details">
                <div className="business-detail-item">
                  <span className="key">Business Name:</span>
                  <div className="business-value-container">
                    {editableFields.businessName ? (
                      <input
                        type="text"
                        value={formData.businessName}
                        className="input-field"
                        autoFocus
                        onChange={(e) => handleChange(e, "businessName")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleSave("businessName");
                          }
                        }}
                      />
                    ) : (
                      <>
                        <span className="value">
                          {BusinessData.businessName}
                        </span>
                        <EditIcon
                          className="edit-icon"
                          onClick={() => handleEdit("businessName")}
                        />
                      </>
                    )}
                  </div>
                </div>

                <div className="business-detail-item-for-update-info">
                  <span className="key">Business Email:</span>
                  <div className="business-value-container">
                    {editableFields.businessEmail ? (
                      <input
                        type="text"
                        value={formData.businessEmail}
                        className="input-field"
                        autoFocus
                        onChange={(e) => handleChange(e, "businessEmail")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleSave("businessEmail");
                          }
                        }}
                      />
                    ) : (
                      <>
                        <span className="value">
                          {BusinessData.businessEmail}
                        </span>
                        <EditIcon
                          className="edit-icon"
                          onClick={() => handleEdit("businessEmail")}
                        />
                      </>
                    )}
                  </div>
                </div>

                <div className="business-detail-item-for-update-info">
                  <span className="key">Business Number:</span>
                  <div className="business-value-container">
                    {editableFields.businessNumber ? (
                      <input
                        type="text"
                        value={formData.businessNumber}
                        className="input-field"
                        autoFocus
                        onChange={(e) => handleChange(e, "businessNumber")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleSave("businessNumber");
                          }
                        }}
                      />
                    ) : (
                      <>
                        <span className="value">
                          {BusinessData.businessNumber}
                        </span>
                        <EditIcon
                          className="edit-icon"
                          onClick={() => handleEdit("businessNumber")}
                        />
                      </>
                    )}
                  </div>
                </div>

                <div className="business-detail-item-for-update-info">
                  <span className="key">Ein:</span>
                  <div className="business-value-container">
                    {editableFields.ein ? (
                      <input
                        type="text"
                        value={formData.ein}
                        className="input-field"
                        autoFocus
                        onChange={(e) => handleChange(e, "ein")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleSave("ein");
                          }
                        }}
                      />
                    ) : (
                      <>
                        <span className="value">{BusinessData.ein}</span>
                        <EditIcon
                          className="edit-icon"
                          onClick={() => handleEdit("ein")}
                        />
                      </>
                    )}
                  </div>
                </div>

                <div className="business-detail-item-for-update-info">
                  <span className="key">Address:</span>
                  <div className="business-value-container">
                    {editableFields.businessAddress ? (
                      <textarea
                        value={formData.businessAddress}
                        className="custom-textarea"
                        autoFocus
                        onChange={(e) => handleChange(e, "businessAddress")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleSave("businessAddress");
                          }
                        }}
                      />
                    ) : (
                      <>
                        <span className="value">
                          {BusinessData.businessAddress}
                        </span>
                        <EditIcon
                          className="edit-icon"
                          onClick={() => handleEdit("businessAddress")}
                        />
                      </>
                    )}
                  </div>
                </div>

                {(editableFields.businessName ||
                  editableFields.businessEmail ||
                  editableFields.businessNumber ||
                  editableFields.ein ||
                  editableFields.businessAddress) && (
                  <button
                    onClick={() => {
                      if (editableFields.businessName)
                        handleSave("businessName");
                      if (editableFields.businessNumber)
                        handleSave("businessNumber");
                      if (editableFields.businessEmail)
                        handleSave("businessEmail");
                      if (editableFields.ein) handleSave("ein");
                      if (editableFields.businessAddress)
                        handleSave("businessAddress");
                    }}
                    className="save-btn"
                  >
                    Save
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
