import axios from "axios";

const PREFERENCEAPI = process.env.REACT_APP_PREFERENCE_API_URL;

export const PreferenceService = async (data, accessToken) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };

    const response = await axios.post(PREFERENCEAPI, data, {
      headers: headers,
    });

    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// export const PreferenceService = async (data, accessToken) => {
//   try {
//     const headers = {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${accessToken}`,
//     };

//     const response = await axios.post(
//       "https://devapi.ez-byte.com:5050/groupadmin/api/v1/preference/updatePref",
//       data,
//       { headers: headers }
//     );

//     return response;
//   } catch (error) {
//     console.log(error);
//     throw error;
//   }
// };
