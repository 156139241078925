import { combineReducers } from '@reduxjs/toolkit';
import searchReducer from './searchReducer';
import pageInfoReducer from './pageInfoReducer';
import emailFilesReducer from './emailFilesReducer';
import filesReducer from './filesReducer';
import userReducer from './userReducer';

const rootReducer = combineReducers({
  search: searchReducer,
  pageInfo: pageInfoReducer,
  emailFiles: emailFilesReducer,
  files: filesReducer,
  user: userReducer,
  // Add other reducers here
});

export default rootReducer;