import React from 'react';
import { useSelector } from 'react-redux';

import {
    Refresh,
    CloudUpload,
    Visibility,
    ModeEdit,
    Delete,
    Email,
    Download,
    ContentCut,
    ContentCopy,
    CreateNewFolder,
    ContentPasteOutlined,
    RestoreFromTrash,
    BookmarkBorderOutlined,
    Bookmark,
  } from '@mui/icons-material';


  const DocumentTopActionIcons = ({
    t,
    handleContextMenuOptionClick,
    handleUploadButtonClick,
    openCreateFolderModal,
    cutPaths,
    copiedPaths,
    refreshIconHandler,
    optionsIcons
  }) => {
    const multipleFileSelect = useSelector((state) => state.files.multipleFileSelect);
     const {
        isSearchResults, 
        isRecentResults, 
        isFavoritesResults, 
        isTrashResults 
      } = useSelector((state) => state.pageInfo);

    return (
      <div id="documentContainerIcons" className="container2-options-icon">
        <div className="icon-wrapper refresh" data-label={t("Refresh")} onClick={refreshIconHandler}>
          <Refresh className="action-icon refresh" />
        </div>
  
        {!isRecentResults && !isFavoritesResults && !isTrashResults && !isSearchResults && (
          <div className="icon-wrapper upload" data-label={t("Upload")} onClick={handleUploadButtonClick}>
            <CloudUpload className="action-icon" />
          </div>
        )}
  
        {multipleFileSelect.length === 1 && multipleFileSelect.every((item) => !item.isFolder) && !isTrashResults && (
          <div className="icon-wrapper preview" data-label={t("Preview")} onClick={() => handleContextMenuOptionClick("Preview", multipleFileSelect)}>
            <Visibility className="action-icon" />
          </div>
        )}
  
        {multipleFileSelect.length === 1 && !isRecentResults && !isFavoritesResults && !isTrashResults && (
          <div className="icon-wrapper rename" data-label={t("Rename")} onClick={() => handleContextMenuOptionClick("Rename", multipleFileSelect)}>
            <ModeEdit className="action-icon" />
          </div>
        )}
  
        {multipleFileSelect.length >= 1 && !isTrashResults && (
          <div className="icon-wrapper delete" data-label={t("Delete")} onClick={() => handleContextMenuOptionClick("Delete", multipleFileSelect)}>
            <Delete className="action-icon" />
          </div>
        )}
  
        {multipleFileSelect.length === 1 && !isTrashResults && !isRecentResults && !isFavoritesResults && !isSearchResults && multipleFileSelect.every((item) => item.favorite === "false") && (
          <div className="icon-wrapper fav" data-label={t("AddToFavorite")} onClick={() => handleContextMenuOptionClick("AddToFavorite", multipleFileSelect)}>
            <BookmarkBorderOutlined fontSize="small" className="action-icon" />
          </div>
        )}
  
        {multipleFileSelect.length === 1 && !isSearchResults && (isFavoritesResults || multipleFileSelect.every((item) => item.favorite === "true")) && (
          <div className="icon-wrapper" data-label={t("RemoveFromFavorite")} onClick={() => handleContextMenuOptionClick("RemoveFromFavorite", multipleFileSelect)}>
            <Bookmark fontSize="small" className="action-icon bookmarkremove" />
          </div>
        )}
  
        {multipleFileSelect.length >= 1 && multipleFileSelect.every((item) => !item.isFolder) && !isTrashResults && (
          <div className="icon-wrapper email" data-label={t("Email")} onClick={() => handleContextMenuOptionClick("Email", multipleFileSelect)}>
            <Email className="action-icon" />
          </div>
        )}
  
        {multipleFileSelect.length >= 1 && !isTrashResults && (
          <div className="icon-wrapper download" data-label={t("Download")} onClick={() => handleContextMenuOptionClick("Download", multipleFileSelect)}>
            <Download className="action-icon" />
          </div>
        )}
  
        {multipleFileSelect.length >= 1 && !isRecentResults && !isFavoritesResults && !isTrashResults && (
          <div className="icon-wrapper cut" data-label={t("Cut")} onClick={() => handleContextMenuOptionClick("Cut", multipleFileSelect)}>
            <ContentCut className="action-icon cut" />
          </div>
        )}
  
        {multipleFileSelect.length >= 1 && !isRecentResults && !isFavoritesResults && !isTrashResults && (
          <div className="icon-wrapper copy" data-label={t("Copy")} onClick={() => handleContextMenuOptionClick("Copy", multipleFileSelect)}>
            <ContentCopy className="action-icon copy" />
          </div>
        )}
  
        {!isRecentResults && !isFavoritesResults && !isTrashResults && !isSearchResults && (
          <div className="icon-wrapper folder" data-label={t("Folder")} onClick={openCreateFolderModal}>
            <CreateNewFolder className="action-icon" />
          </div>
        )}
  
        {(cutPaths.length > 0 || copiedPaths.length > 0) && !isTrashResults && !isFavoritesResults && !isRecentResults && isSearchResults === false && (
          <div className="icon-wrapper paste" data-label={t("Paste")} onClick={() => handleContextMenuOptionClick("Paste", multipleFileSelect)}>
            <ContentPasteOutlined className="action-icon paste" />
          </div>
        )}
  
        {multipleFileSelect.length >= 1 && isTrashResults && (
          <div className="icon-wrapper" data-label={t("Restore")}
           onClick={() => handleContextMenuOptionClick("Restore", multipleFileSelect)}>
            <RestoreFromTrash className="action-icon" />
          </div>
        )}
        
      </div>
      
    );
  };
  
  export default DocumentTopActionIcons;