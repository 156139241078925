import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import "../screens/EmailScreen.css";
import { auth, db } from "../config/firebase";
import { doc, getDoc } from "firebase/firestore";
import { RingLoader } from "react-spinners";
import { signOut } from "firebase/auth";
import emailService from "../services/emailService";
import { useUserDetails } from "../customHook/userDetails";
import EmailTagsInput from "../components/EmailTagsInput";
import { useSelector, useDispatch } from 'react-redux';
import { clearEmailsList, setEmailsList } from '../store/reducers/emailFilesReducer';
import { Tooltip } from "@mui/material";
import { useTranslation } from 'react-i18next';

export default function EmailScreen({ closeOverlay, accessToken, selectedFilesToEmail }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const emailsValid = useSelector((state) => state.emailFiles.emailsValid);
  const toEmails = useSelector((state) => state.emailFiles.toEmails);
  const ccEmails = useSelector((state) => state.emailFiles.ccEmails);
  const bccEmails = useSelector((state) => state.emailFiles.bccEmails);
  const [userDetail, setUserDetail] = useState(null);
  const [btnSendLabel, setBtnSendLabel] = useState(t("Send"));
  const [isSendBtnDisabled, setIsSendBtnDisabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const refEmailBodyTextBox = useRef(null);
  const refSubjectTextBox = useRef(null);
  const refToField = useRef(null); // Ref for the "To" field
  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState(
    selectedFilesToEmail || []
  );
  const [dropdownValue, setDropdownValue] = useState("Select");
  const [showCc, setShowCc] = useState(false); // State for "Cc" visibility
  const [showBcc, setShowBcc] = useState(false); // State for "Bcc" visibility

  //  Email Model
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");

  //  Error States
  const [fileError, setFileError] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [bodyError, setBodyError] = useState("");
  const [emailSentStatus, setEmailSentStatus] = useState("");
  const [emailResult, setEmailResult] = useState(false);

  useEffect(() => {
    if (loading) {
      console.log("Loading state: ", loading);
      return;
    }
    if (!userDetail) {
      console.error("No user is signed in.");
      navigate("/");
    }
  }, [loading, userDetail, navigate]);

  useEffect(() => {
    if (error) {
      console.error("Error state: ", error);
    }
  }, [error]);

  useEffect(() => {
    const fetchUserDetail = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const docRef = doc(db, "Users", user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            setUserDetail(docSnap.data());
          } else {
            setError("User data not found.");
          }
        } else {
          setError("No user is signed in.");
        }
      } catch (err) {
        setError("Failed to fetch user data.");
        console.error("Error fetching user data:", err);
      } finally {
        setLoading(false);
      }
    };
    fetchUserDetail();
  }, []);

  useEffect(() => {
    if (subject.length > 0) {
      setSubjectError("");
    }
  }, [subject]);

  useEffect(() => {
    if (body.length > 0) {
      setBodyError("");
    }
  }, [body]);

  const validateSubject = () => {
    if (subject.trim().length === 0) {
      setSubjectError(t("providesubject"));
      return false;
    } else {
      setSubjectError("");
      return true;
    }
  };

  const validateEmailBody = () => {
    if (body.trim().length === 0) {
      setBodyError(t("providebody"));
      return false;
    } else {
      setBodyError("");
      return true;
    }
  };

  //  Function to handle email submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFileError("");
    setEmailSentStatus(""); // Clear previous status messages

    // Check if files are selected
    if (selectedFiles.length === 0) {
      setFileError("Please add files to proceed.");
      return false;
    }

    if (emailsValid === false) {
      return false;
    }

    const emailData = {
      to: toEmails.join(","),
      cc: ccEmails.join(","),
      bcc: bccEmails.join(","),
      subject,
      body,
      selectedFiles: selectedFiles.map((file) => ({
        name: file.name,
        type: file.type,
        path: file.path,
      })),
    };

    if (validateSubject() === false) {
      return false;
    }
    if (validateEmailBody() === false) {
      return false;
    }

    try {
      // API POST CALL
      console.log("Sending email:", emailData);
      setBtnSendLabel(t("Sending"));
      setIsSendBtnDisabled(true);
      let _emailResult = await emailService(emailData, accessToken, t("files_attached"));
      setEmailResult(_emailResult);
      if (_emailResult == true) {
        dispatch(clearEmailsList());
        setSubject("");
        setBody("");
        setEmailSentStatus(t("Emailsentsuccessfully"));
        closeOverlay(true);
      } else {
        setEmailSentStatus(t("Emailnotsent"));
      }
    } catch (err) {
      console.log("Error sending email:", err);
      setEmailSentStatus(t("Emailnotsent"));
    }
    setBtnSendLabel("Send");
    setIsSendBtnDisabled(false);

  };

  if (loading) {
    return (
      <div className="spinner-container">
        <RingLoader color="#3498db" />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!userDetail) {
    return <div>Error: No user is signed in.</div>;
  }

  // Function to remove a file
  const handleDeleteFile = (fileName) => {
    const updatedFiles = selectedFiles.filter((file) => file.name !== fileName);
    setSelectedFiles(updatedFiles);

    if (updatedFiles.length === 0) {
      navigate("/home");
    }
  };

  const formatFileSize = (size) => {
    if (size < 1024) return size + " KB";
    else if (size < 1048576) return (size / 1024).toFixed(2) + " MB";
    else return (size / 1048576).toFixed(2) + " GB";
  };
  const extractFileName = (filePath) => {
    return filePath.split("/").pop();
  };

  return (
    <>
      <div className="home-body">
        <div id="emailModal" className="email-container">
          <div className="email-form-container">
            <form id="emailForm" className="email-form"
              onSubmit={handleSubmit}
              onKeyDown={(e) => e.key === "Enter" && handleSubmit(e)}>
              <div className="input-group">
                <div className="input-wrapper">
                  <EmailTagsInput
                    placeholder={t("To")}
                    tags={toEmails}
                    setTags={(tags) => dispatch(setEmailsList({ field: 'toEmails', emails: tags }))}
                    maxCount={5}
                    ref={refToField} // Pass the ref to the "To" field
                    required={true} // Mark the "To" field as required
                    field="toEmails"
                  />
                </div>
              </div>
              {/* Conditional rendering of Cc and Bcc fields based on state */}
              {showCc && (
                <div className="input-group">
                  <div className="input-wrapper">
                    <EmailTagsInput
                      placeholder={t("Cc")}
                      tags={ccEmails}
                      setTags={(tags) => dispatch(setEmailsList({ field: 'ccEmails', emails: tags }))}
                      maxCount={5}
                      required={false} // Mark the "Cc" field as not required
                      field="ccEmails"
                    />
                  </div>
                </div>
              )}
              {showBcc && (
                <div className="input-group">
                  <div className="input-wrapper">
                    <EmailTagsInput
                      placeholder={t("Bcc")}
                      tags={bccEmails}
                      setTags={(tags) => dispatch(setEmailsList({ field: 'bccEmails', emails: tags }))}
                      maxCount={5}
                      required={false} // Mark the "Bcc" field as not required
                      field="bccEmails"
                    />
                  </div>
                </div>
              )}
              <div className="input-group">
                <div className="input-wrapper">
                  <input
                    id="emailSubject"
                    type="text"
                    placeholder={t("Subject")}
                    className="subject-input"
                    value={subject}
                    maxLength={250}
                    onChange={(e) => setSubject(e.target.value)}
                    ref={refSubjectTextBox}
                    onBlur={validateSubject}
                  />
                  {subjectError && (
                    <p className="email-error-message error-message">
                      {subjectError}
                    </p>
                  )}
                </div>
              </div>
              <div className="input-group">
                <div className="input-wrapper">
                  <textarea
                    id="emailBody"
                    placeholder={t("Body")}
                    className="body-input"
                    value={body}
                    rows={3}
                    onChange={(e) => setBody(e.target.value)}
                    ref={refEmailBodyTextBox}
                    onBlur={validateEmailBody}
                  />
                  {bodyError && (
                    <p className="email-error-message error-message">
                      {bodyError}
                    </p>
                  )}
                </div>
              </div>
              <button
                id="emailSend"
                type="submit"
                className="proceed-button"
                disabled={isSendBtnDisabled}
              >
                {btnSendLabel}
              </button>
            </form>

            {emailSentStatus && (
              <p className={emailResult ? "sucess-message" : "error-message"}>
                {emailSentStatus}
              </p>
            )}

            {selectedFiles.length > 0 ? (
              <div className="file-table custom-scrollbar" style={{ width: "98%" }}>
                <table id="emailFilesTable" style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th style={{ width: "10%" }}>{t('Delete')}</th>
                      <th style={{ width: "70%" }}>{t('FileName')}</th>
                      <th style={{ width: "20%" }}>{t('FileSize')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedFiles.map((file) => (
                      <tr key={file.name}>
                        <td>
                          <button
                            type="submit"
                            className="delete-button"
                            onClick={() => handleDeleteFile(file.name)}
                          >
                            X
                          </button>
                        </td>
                        <td>
                          <Tooltip
                            style={{
                              maxWidth: "400px", whiteSpace: "nowrap",
                              overflow: "hidden", textOverflow: "ellipsis",
                              display: "inline-block",
                              verticalAlign: "middle", backgroundColor: "white",
                              fontSize: "11px", fontWeight: 400
                            }}
                            arrow={false}
                            title={extractFileName(file.name)}>{file.name}
                          </Tooltip>
                        </td>
                        <td style={{ textAlign: "left", fontSize: "11px" }}>{formatFileSize(file.size)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              !emailResult && (
                <p className="file-message">
                  {t('Nofilesuploded')}
                </p>
              )
            )}
          </div>
          {/* Toggle Buttons for Cc and Bcc */}
          <div className="toggle-cc-bcc">
            <button
              type="button"
              className="toggle-button"
              onClick={() => setShowCc(!showCc)}
            >
              {showCc ? t("Cc") : t("Cc")}
            </button>
            <button
              type="button"
              className="toggle-button"
              onClick={() => setShowBcc(!showBcc)}
            >
              {showBcc ? t("Bcc") : t("Bcc")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}