export const RENAME_API_URL = process.env.REACT_APP_RENAME_API_URL;
export default async function RenameService(renameData, accessToken) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${accessToken}`);
  const renamePayload = JSON.stringify({
    renameFileList: renameData.map((file) => {
      let newName;
      if (file.isFolder) {
        newName = file.newName.endsWith("/")
          ? file.newName
          : `${file.newName}/`;
      } else {
        newName = file.newName;
      }

      return {
        currentName: file.currentName,
        newName: newName,
      };
    }),
  });

  const requestOptions = {
    method: "POST",
    headers: headers,
    body: renamePayload,
  };

  try {
    const response = await fetch(`${RENAME_API_URL}`, requestOptions);
    if (response.ok) {
      const result = await response.text();
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Fetch error:", error);
    return false;
  }
}