import React from "react";
import { useTranslation } from "react-i18next";
import {
  NotificationsNoneOutlined,
  ReviewsOutlined,
} from "@mui/icons-material";
import { Badge } from "@mui/material";
import LanguageSwitcher from "../LanguageSwitcher";
import appLogo from "../../assets/alterprofile.png";

export default function IconsSection({
  handleFeedback,
  photoURL,
  toggleDrawer,
}) {
  const { t } = useTranslation();

  return (
    <div id="settingIcons" className="header1-icons">
      {/* <div className="lang-button">
        <LanguageSwitcher />
      </div> */}

      <span>
        <div className="tooltip-container" onClick={handleFeedback}>
          <ReviewsOutlined id="imgReviewFeedback" />
          <span id="mainHeaderFeedback" className="tooltip-text">
            {t("Providefeedback")}
          </span>
        </div>

        <div
          id="notificationBadgeContainer"
          style={{
            marginLeft: "-5px",
            marginRight: "5px",
            marginTop: "0px",
          }}
        >
          <Badge
            badgeContent={3}
            sx={{
              "& .MuiBadge-badge": {
                backgroundColor: "#FF5B61",
                color: "white",
                fontSize: "10px",
                height: "16px",
                minWidth: "16px",
                padding: "0 4px",
              },
            }}
          >
            <NotificationsNoneOutlined id="notificationIcon" />
          </Badge>
        </div>
      </span>
      <span>
        <div className="drawer-content">
          <img
            id="imgProfile"
            src={photoURL || appLogo}
            alt="profile"
            onClick={toggleDrawer}
            draggable="false"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = appLogo;
            }}
            className="profile-image"
          />
        </div>
      </span>
    </div>
  );
}
