import "../HeaderComponents/UserManagementOverlay.css";

import { PersonAddAlt, DeleteOutlineOutlined, Edit } from "@mui/icons-material";
import axios from "axios";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { validateEmail } from "../../utils/emailValidationUtils";

// const GET_USER_API =
//   "https://devapi.ez-byte.com:5050/groupadmin/api/getGroupInfo";

const GET_USER_API = process.env.REACT_APP_GET_USER_API_URL;
const CREATE_GROUP_USER = process.env.REACT_APP_CREATE_GROUP_USER_URL;

// const CREATE_GROUP_USER_URL =
//   "https://devapi.ez-byte.com:5050/groupadmin/api/createGroupUser";

export default function UserManagementOverlay({
  isModalOpen,
  closeModal,
  accessToken,
}) {
  const [groupInfoData, setGroupInfoData] = useState();
  const [responseOfcreateUser, setResponseOfCreateUser] = useState();
  const [displayEmailInputError, setDisplayEmailInputError] = useState("");
  const [displayCapacityInputError, setDisplayCapacityInputError] =
    useState("");

  const [isOpenAddPopUp, setIsOpenAddPopUp] = useState(false);
  const [isMessageVisible, setMessageVisible] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  const [email, setEmail] = useState("");
  const [capacity, setCapacity] = useState("");

  const [isEditIndex, setEditIndex] = useState(null);
  const [value, setValue] = useState("");

  const { t } = useTranslation();

  // console.log(
  //   "GROUP INFO DATA: ",
  //   groupInfoData?.data?.inviationList.map((item) => item.userEmail)
  // );

  const headers = {
    "Cache-Control": "no-cache",
    Authorization: `Bearer ${accessToken}`,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(GET_USER_API, { headers });

        const data = await response.data;

        setGroupInfoData(data);

        const cap = data.data?.usersInGroupList.map((user) => user.currentUse);

        console.log(`-------------------------CAP----------`);
        console.log(`-------------------------CAP----------`);
        console.log(cap);
        console.log(`-------------------------CAP----------`);
        console.log(`-------------------------CAP----------`);

        // console.log(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [showSuccessMessage]);

  const openDeleteConfirm = () => {
    setIsDeleteConfirmOpen(true);
  };
  const closeDeleteConfirm = () => {
    setIsDeleteConfirmOpen(false);
  };

  const openAddUserBtn = () => {
    setIsOpenAddPopUp(true);

    setEmail("");
    setCapacity("");
    setDisplayEmailInputError("");
    setDisplayCapacityInputError("");
  };

  const closeAddUserBtn = () => {
    setIsOpenAddPopUp(false);
  };

  const successPopUpClose = () => {
    setShowSuccessMessage(false);
  };

  const handleSubmit = async () => {
    if (!validateEmail(email)) {
      setDisplayEmailInputError("Please enter a valid email");
      return;
    }

    const capacityRegex = /^[+]?\d+(\.\d+)?$/; // Regular expression for positive numbers with optional decimal
    if (!capacityRegex.test(capacity.trim())) {
      setDisplayCapacityInputError("Please enter a valid capacity");
      return;
    }

    setDisplayEmailInputError("");
    setDisplayCapacityInputError("");

    const payload = {
      groupUserList: [
        {
          groupUserEmail: email,
          maxAllowedCapacity: Number(capacity),
        },
      ],
    };

    try {
      const response = await axios.post(
        CREATE_GROUP_USER,
        payload,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      console.log("RESPONSE :", response);
      console.log("RESPONSE DATA", response.data);

      setResponseOfCreateUser(response.data);

      if (response.data.data !== null) {
        setShowSuccessMessage(true);
        setIsOpenAddPopUp(false);
      }

      if (response.data.data === null) {
        console.log("");
        setMessageVisible(true);

        setTimeout(() => setMessageVisible(false), 5000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditlick = (index, myUser) => {
    if (myUser.roles.includes("ADMIN")) return;

    setEditIndex(index);
    setValue(groupInfoData.data?.usersInGroupList[index].currentUse); // initial value
  };

  const handleBlur = (index, email) => {
    console.log(`---------------------------EMAIL-----------------`);
    console.log(`---------------------------EMAIL-----------------`);
    console.log(email);
    console.log(`---------------------------EMAIL-----------------`);
    console.log(`---------------------------EMAIL-----------------`);

    setEditIndex(null);

    setGroupInfoData((prevState) => ({
      // ...prevState, groupInfoData.data?.usersInGroupList:  prevState.data?.usersInGroupList.map((curUse, i) => i === index ? {...curUse, currentUse: value} : )

      data: {
        ...prevState.data,
        usersInGroupList: prevState.data?.usersInGroupList.map((curUse, i) =>
          i === index ? { ...curUse, currentUse: value } : curUse
        ),
      },
    }));
  };

  return (
    <>
      {isModalOpen && (
        <div id="modalOverlay" className="modal-overlay" onClick={closeModal}>
          <div
            id="ModalContainer"
            className="modal-container"
            onClick={(e) => e.stopPropagation()}
          >
            <div id="ModalHeader" className="modal-header">
              <h2>{t("ManageUsers")}</h2>
              <button
                id="closeButton"
                className="close-button"
                onClick={closeModal}
              >
                &times;
              </button>
            </div>

            <button
              id="addUserButton"
              className="add-user-btn"
              onClick={openAddUserBtn}
            >
              <span>
                {t("Addusers")} <PersonAddAlt />
              </span>
            </button>

            {/* Table Section */}
            <div id="tableWrapper" className="table-wrapper">
              <div className="table-container">
                <table className="table">
                  <thead>
                    <tr>
                      <th>{t("Email")}</th>
                      <th>{t("Role")}</th>
                      <th>{t("Status")}</th>
                      <th>{t("Enabled")}</th>
                      <th>{t("Size")}</th>
                      <th>{t("Usage")}</th>
                      <th>{t("Delete")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupInfoData.data?.usersInGroupList.length > 0 &&
                      groupInfoData.data?.usersInGroupList.map(
                        (myUser, index) => (
                          <tr key={index}>
                            <td>{myUser.email}</td>
                            {/* <td>{myUser.roles.map((item) => item)}</td> */}
                            <td>{myUser.roles.join(", ")}</td>

                            <td>
                              <span
                                className={`label ${
                                  myUser.active === true
                                    ? "label-live"
                                    : "label-no"
                                }`}
                              >
                                {myUser.active === true ?t("active") :t("Inactive") }
                              </span>
                            </td>

                            <td>{t("Need")}</td>
                            <td>{t("Need")}</td>

                            <td>
                              {isEditIndex === index ? (
                                <input
                                  type="text"
                                  value={value}
                                  autoFocus
                                  onChange={(e) => setValue(e.target.value)}
                                  onBlur={() => handleBlur(index, myUser.email)}
                                  style={{
                                    border: "1px solid #ccc",
                                    padding: "3px",
                                    borderRadius: "4px",
                                    width: "25px",
                                  }}
                                />
                              ) : (
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                    background: "white",
                                  }}
                                  onClick={() => handleEditlick(index, myUser)}
                                >
                                  {myUser.currentUse}
                                  {!myUser.roles.includes("ADMIN") && (
                                    <Edit
                                      style={{
                                        width: "15px",
                                        background: "white",
                                      }}
                                    />
                                  )}
                                </span>
                              )}
                            </td>

                            {/* <td>
                              <DeleteOutlineOutlined
                                style={{ width: "15px", background: "white" }}
                                onClick={() => openDeleteConfirm(index)}
                              />
                            </td> */}

                            <td>
                              {!myUser.roles.includes("ADMIN") && (
                                <DeleteOutlineOutlined
                                  style={{ width: "15px", background: "white" }}
                                  onClick={() => openDeleteConfirm(index)}
                                />
                              )}
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>

                  {/* ---------------INVITE ONLY-------- */}

                  <tbody>
                    {groupInfoData.data?.inviationList?.length > 0 &&
                      groupInfoData.data?.inviationList?.map(
                        (myUser, index) => (
                          <tr key={index}>
                            <td>{myUser.userEmail}</td>
                            <td>-</td>
                            <td>
                              <span
                                // className={`label ${
                                //   myUser.active === true
                                //     ? "label-live"
                                //     : "label-no"
                                // }`}

                                className="invite-label invite-label-no"
                              >
                                Invitation Pending
                              </span>
                            </td>

                            <td>-</td>
                            <td>-</td>

                            <td>
                              {
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                    background: "white",
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  {myUser.maxAllowedCapacity}

                                  {/* <Edit
                                    style={{
                                      width: "15px",
                                      background: "white",
                                    }}
                                  /> */}
                                </span>
                              }
                            </td>

                            <td>-</td>
                          </tr>
                        )
                      )}
                  </tbody>

                  {/* ---------------INVITE ONLY-------- */}
                </table>
              </div>
            </div>
          </div>
        </div>
      )}

      {isOpenAddPopUp && (
        <div className="add-confirm-overlay" onClick={closeDeleteConfirm}>
          <div
            id="addConfirmContainer"
            className="add-confirm-container"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="input-group">
              <input
                type="text"
                id="groupEmail"
                placeholder={t("Email")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {displayEmailInputError && (
                <p className="show-input-error">{displayEmailInputError}</p>
              )}

              <input
                type="number"
                min="0"
                max="1000"
                id="maxCapacity"
                placeholder={t("Capacity")}
                value={capacity}
                onChange={(e) => setCapacity(e.target.value)}
              />
              {displayCapacityInputError && (
                <p className="show-input-error">{displayCapacityInputError}</p>
              )}
            </div>
            {responseOfcreateUser?.data === null && isMessageVisible && (
              <p className="message-note">{`(i.e) ${responseOfcreateUser.message}`}</p>
            )}
            <div id="buttonGroup" className="button-group">
              <button id="buttonYes" className="btn-yes" onClick={handleSubmit}>
              {t("Add")}
              </button>
              <button
                id="buttonNo"
                className="btn-no"
                onClick={closeAddUserBtn}
              >
                {t("Cancel")}
              </button>
            </div>
          </div>
        </div>
      )}

      {isDeleteConfirmOpen && (
        <div className="delete-confirm-overlay" onClick={closeDeleteConfirm}>
          <div
            id="deleteConfirmContainer"
            className="delete-confirm-container"
            onClick={(e) => e.stopPropagation()}
          >
            <h3>Are you sure you want to delete this User?</h3>
            <button
              id="buttonProceed"
              className="btn-yes"
              onClick={closeDeleteConfirm}
            >
              Proceed
            </button>
            <button id="Cancel" className="btn-no" onClick={closeDeleteConfirm}>
              Cancel
            </button>
          </div>
        </div>
      )}

      {responseOfcreateUser?.data !== null && showSuccessMessage && (
        <div className="success-confirm-overlay" onClick={closeDeleteConfirm}>
          <div
            id="successConfirmContainer"
            className="success-confirm-container"
            onClick={(e) => e.stopPropagation()}
          >
            <h3>{responseOfcreateUser.data[0]}</h3>
            <button
              id="buttonOk"
              className="btn-ok"
              onClick={successPopUpClose}
            >
              Ok
            </button>
          </div>
        </div>
      )}
    </>
  );
}

// export default function UserManagementOverlay({
//   isModalOpen,
//   closeModal,
//   accessToken,
// }) {
//   const [groupInfoData, setGroupInfoData] = useState();
//   const [responseOfcreateUser, setResponseOfCreateUser] = useState();
//   const [displayEmailInputError, setDisplayEmailInputError] = useState("");
//   const [displayCapacityInputError, setDisplayCapacityInputError] =
//     useState("");

//   const [isOpenAddPopUp, setIsOpenAddPopUp] = useState(false);
//   const [isMessageVisible, setMessageVisible] = useState(false);
//   const [showSuccessMessage, setShowSuccessMessage] = useState(false);
//   const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

//   const [email, setEmail] = useState("");
//   const [capacity, setCapacity] = useState("");

//   const [isEditCapacity, setIsEditCapacity] = useState(false);
//   const [capacityAfterEditing, setCapacityAfterEditing] = useState("");

//   const { t } = useTranslation();

//   // console.log(
//   //   "GROUP INFO DATA: ",
//   //   groupInfoData?.data?.inviationList.map((item) => item.userEmail)
//   // );

//   const headers = {
//     "Cache-Control": "no-cache",
//     Authorization: `Bearer ${accessToken}`,
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(GET_USER_API, { headers });

//         const data = await response.data;

//         setGroupInfoData(data);

//         const cap = data.data?.usersInGroupList.map((user) => user.currentUse);

//         console.log(`-------------------------CAP----------`);
//         console.log(`-------------------------CAP----------`);
//         console.log(cap);
//         console.log(`-------------------------CAP----------`);
//         console.log(`-------------------------CAP----------`);

//         // console.log(data);
//       } catch (error) {
//         console.log(error);
//       }
//     };

//     fetchData();
//   }, [showSuccessMessage]);

//   const openDeleteConfirm = () => {
//     setIsDeleteConfirmOpen(true);
//   };
//   const closeDeleteConfirm = () => {
//     setIsDeleteConfirmOpen(false);
//   };

//   const openAddUserBtn = () => {
//     setIsOpenAddPopUp(true);

//     setEmail("");
//     setCapacity("");
//     setDisplayEmailInputError("");
//     setDisplayCapacityInputError("");
//   };

//   const closeAddUserBtn = () => {
//     setIsOpenAddPopUp(false);
//   };

//   const successPopUpClose = () => {
//     setShowSuccessMessage(false);
//   };

//   const handleSubmit = async () => {
//     if (!validateEmail(email)) {
//       setDisplayEmailInputError("Please enter a valid email");
//       return;
//     }

//     const capacityRegex = /^[+]?\d+(\.\d+)?$/; // Regular expression for positive numbers with optional decimal
//     if (!capacityRegex.test(capacity.trim())) {
//       setDisplayCapacityInputError("Please enter a valid capacity");
//       return;
//     }

//     setDisplayEmailInputError("");
//     setDisplayCapacityInputError("");

//     const payload = {
//       groupUserList: [
//         {
//           groupUserEmail: email,
//           maxAllowedCapacity: Number(capacity),
//         },
//       ],
//     };

//     try {
//       const response = await axios.post(
//         CREATE_GROUP_USER,
//         payload,

//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${accessToken}`,
//           },
//         }
//       );

//       console.log("RESPONSE :", response);
//       console.log("RESPONSE DATA", response.data);

//       setResponseOfCreateUser(response.data);

//       if (response.data.data !== null) {
//         setShowSuccessMessage(true);
//         setIsOpenAddPopUp(false);
//       }

//       if (response.data.data === null) {
//         console.log("");
//         setMessageVisible(true);

//         setTimeout(() => setMessageVisible(false), 5000);
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleEditlick = (index) => {};

//   const handleBlur = (index) => {};

//   return (
//     <>
//       {isModalOpen && (
//         <div id="modalOverlay" className="modal-overlay" onClick={closeModal}>
//           <div
//             id="ModalContainer"
//             className="modal-container"
//             onClick={(e) => e.stopPropagation()}
//           >
//             <div id="ModalHeader" className="modal-header">
//               <h2>{t("ManageUsers")}</h2>
//               <button
//                 id="closeButton"
//                 className="close-button"
//                 onClick={closeModal}
//               >
//                 &times;
//               </button>
//             </div>

//             <button
//               id="addUserButton"
//               className="add-user-btn"
//               onClick={openAddUserBtn}
//             >
//               <span>
//                 {t("Addusers")} <PersonAddAlt />
//               </span>
//             </button>

//             {/* Table Section */}
//             <div id="tableWrapper" className="table-wrapper">
//               <div className="table-container">
//                 <table className="table">
//                   <thead>
//                     <tr>
//                       <th>{t("Email")}</th>
//                       <th>{t("Role")}</th>
//                       <th>{t("Status")}</th>
//                       <th>{t("Enabled")}</th>
//                       <th>{t("Size")}</th>
//                       <th>{t("Usage")}</th>
//                       <th>{t("Delete")}</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {groupInfoData.data?.usersInGroupList.length > 0 &&
//                       groupInfoData.data?.usersInGroupList.map(
//                         (myUser, index) => (
//                           <tr key={index}>
//                             <td>{myUser.email}</td>
//                             {/* <td>{myUser.roles.map((item) => item)}</td> */}
//                             <td>{myUser.roles.join(", ")}</td>

//                             <td>
//                               <span
//                                 className={`label ${
//                                   myUser.active === true
//                                     ? "label-live"
//                                     : "label-no"
//                                 }`}
//                               >
//                                 {myUser.active === true ? "active" : "Inactive"}
//                               </span>
//                             </td>

//                             <td>Need</td>
//                             <td>Need</td>

//                             <td>
//                               {
//                                 <span
//                                   style={{
//                                     display: "flex",
//                                     alignItems: "center",
//                                     gap: "5px",
//                                     background: "white",
//                                   }}
//                                 >
//                                   {myUser.currentUse}
//                                   <Edit
//                                     style={{
//                                       width: "15px",
//                                       background: "white",
//                                     }}
//                                   />
//                                 </span>
//                               }
//                             </td>

//                             <td>
//                               <DeleteOutlineOutlined
//                                 style={{ width: "15px", background: "white" }}
//                                 onClick={() => openDeleteConfirm(index)}
//                               />
//                             </td>
//                           </tr>
//                         )
//                       )}
//                   </tbody>

//                   {/* ---------------INVITE ONLY-------- */}

//                   <tbody>
//                     {groupInfoData.data?.inviationList?.length > 0 &&
//                       groupInfoData.data?.inviationList?.map(
//                         (myUser, index) => (
//                           <tr key={index}>
//                             <td>{myUser.userEmail}</td>
//                             <td>-</td>
//                             <td>
//                               <span
//                                 // className={`label ${
//                                 //   myUser.active === true
//                                 //     ? "label-live"
//                                 //     : "label-no"
//                                 // }`}

//                                 className="invite-label invite-label-no"
//                               >
//                                 Invitation Pending
//                               </span>
//                             </td>

//                             <td>-</td>
//                             <td>-</td>

//                             <td>
//                               {
//                                 <span
//                                   style={{
//                                     display: "flex",
//                                     alignItems: "center",
//                                     gap: "5px",
//                                     background: "white",
//                                   }}
//                                 >
//                                   {myUser.maxAllowedCapacity}
//                                   <Edit
//                                     style={{
//                                       width: "15px",
//                                       background: "white",
//                                     }}
//                                   />
//                                 </span>
//                               }
//                             </td>

//                             <td>-</td>
//                           </tr>
//                         )
//                       )}
//                   </tbody>

//                   {/* ---------------INVITE ONLY-------- */}
//                 </table>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}

//       {isOpenAddPopUp && (
//         <div className="add-confirm-overlay" onClick={closeDeleteConfirm}>
//           <div
//             id="addConfirmContainer"
//             className="add-confirm-container"
//             onClick={(e) => e.stopPropagation()}
//           >
//             <div className="input-group">
//               <input
//                 type="text"
//                 id="groupEmail"
//                 placeholder="Email"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//               />
//               {displayEmailInputError && (
//                 <p className="show-input-error">{displayEmailInputError}</p>
//               )}

//               <input
//                 type="number"
//                 min="0"
//                 max="1000"
//                 id="maxCapacity"
//                 placeholder="Capacity"
//                 value={capacity}
//                 onChange={(e) => setCapacity(e.target.value)}
//               />
//               {displayCapacityInputError && (
//                 <p className="show-input-error">{displayCapacityInputError}</p>
//               )}
//             </div>
//             {responseOfcreateUser?.data === null && isMessageVisible && (
//               <p className="message-note">{`(i.e) ${responseOfcreateUser.message}`}</p>
//             )}
//             <div id="buttonGroup" className="button-group">
//               <button id="buttonYes" className="btn-yes" onClick={handleSubmit}>
//                 Add
//               </button>
//               <button
//                 id="buttonNo"
//                 className="btn-no"
//                 onClick={closeAddUserBtn}
//               >
//                 Cancel
//               </button>
//             </div>
//           </div>
//         </div>
//       )}

//       {isDeleteConfirmOpen && (
//         <div className="delete-confirm-overlay" onClick={closeDeleteConfirm}>
//           <div
//             id="deleteConfirmContainer"
//             className="delete-confirm-container"
//             onClick={(e) => e.stopPropagation()}
//           >
//             <h3>Are you sure you want to delete this User?</h3>
//             <button
//               id="buttonProceed"
//               className="btn-yes"
//               onClick={closeDeleteConfirm}
//             >
//               Proceed
//             </button>
//             <button id="Cancel" className="btn-no" onClick={closeDeleteConfirm}>
//               Cancel
//             </button>
//           </div>
//         </div>
//       )}

//       {responseOfcreateUser?.data !== null && showSuccessMessage && (
//         <div className="success-confirm-overlay" onClick={closeDeleteConfirm}>
//           <div
//             id="successConfirmContainer"
//             className="success-confirm-container"
//             onClick={(e) => e.stopPropagation()}
//           >
//             <h3>{responseOfcreateUser.data[0]}</h3>
//             <button
//               id="buttonOk"
//               className="btn-ok"
//               onClick={successPopUpClose}
//             >
//               Ok
//             </button>
//           </div>
//         </div>
//       )}
//     </>
//   );
// }

// --------------------------------------

// <table className="table">
//                   <thead>
//                     <tr>
//                       <th>{t("carName")}</th>
//                       <th>{t("carRange")}</th>
//                       <th>{t("carValue")}</th>
//                       <th>{t("Delete")}</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {carWheel.usage.length > 0 &&
//                       carWheel.usage.length.map(
//                         (myUsage, index) => (
//                           <tr key={index}>
//                             <td>{myUsage.carName}</td>

//                             <td>{myUsage.carRange}</td>

//                             <td>
//                               {
//                                 <span

//                                 >
//                                   {myUsage.wheel}
//                                   <Edit
//                                     style={{
//                                       width: "15px",
//                                       background: "white",
//                                     }}
//                                   />
//                                 </span>
//                               }
//                             </td>

//                             <td>
//                               <DeleteOutlineOutlined

//                               />
//                             </td>
//                           </tr>
//                         )
//                       )}
//                   </tbody>
