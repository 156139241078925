import { toast } from "react-toastify";
import CutServiceForDragAndDrop from "../services/cutServiceForDragandDrop";
import { clearMultipleFileSelect } from "../store/reducers/filesReducer";

const extractFileName = (filePath) => {
    return filePath.split("/").pop();
};

export const handleIndividualDragStart = (event, item, multipleFileSelect, setIsDragging) => {
  setIsDragging(true);

  if (multipleFileSelect.length > 1) {
    // Handling multiple file selection
    event.dataTransfer.setData(
      "draggedMultipleFiles",
      JSON.stringify(multipleFileSelect)
    );

    // Create a drag card for multiple items
    const dragCard = document.createElement("div");
    dragCard.style.position = "absolute";
    dragCard.style.width = "100px";
    dragCard.style.height = "20px";
    dragCard.style.backgroundColor = "white";
    dragCard.style.border = "1px solid gray";
    dragCard.style.borderRadius = "5px";
    dragCard.style.padding = "10px";
    dragCard.style.textAlign = "center";
    dragCard.style.color = "black";
    dragCard.style.fontWeight = "500";
    dragCard.style.fontSize = "11px";
    dragCard.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)";
    dragCard.innerText = `${multipleFileSelect.length} items selected`;
    document.body.appendChild(dragCard);

    // Set the drag image
    event.dataTransfer.setDragImage(dragCard, 75, 25);

    // Remove the drag card after setting the drag image
    setTimeout(() => document.body.removeChild(dragCard), 0);
  } else {
    // Handling single file selection
    event.dataTransfer.setData("draggedSingleFile", JSON.stringify(item));

    // Create a drag card for a single item
    const dragCard = document.createElement("div");
    dragCard.style.position = "absolute";
    dragCard.style.width = "100px";
    dragCard.style.height = "20px";
    dragCard.style.backgroundColor = "white";
    dragCard.style.border = "1px solid gray";
    dragCard.style.borderRadius = "5px";
    dragCard.style.padding = "10px";
    dragCard.style.textAlign = "center";
    dragCard.style.color = "black";
    dragCard.style.fontWeight = "500";
    dragCard.style.fontSize = "10px";
    dragCard.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)";

    dragCard.innerText = extractFileName(item.name);
    document.body.appendChild(dragCard);

    // Set the drag image
    event.dataTransfer.setDragImage(dragCard, 75, 25);

    // Remove the drag card after setting the drag image
    setTimeout(() => document.body.removeChild(dragCard), 0);
  }
};

export const handleIndividualDrop = async (
  dispatch,
  event,
  targetItem,
  accessToken,
  handleDrop,
  handleDragOver,
  handleDragLeave,
  setHoveringItems,
  handelRefreshAfterAction,
) => {
  if (event.dataTransfer.types.includes("Files")) {
    console.log("External Files Not allowed");

    handleDrop(event);
    handleDragOver(event);
    handleDragLeave(event);

    return;
  }

  event.preventDefault();
  event.stopPropagation();

  if (event.dataTransfer.types.includes("Files")) {
    console.log("External Files Not allowed");

    return;
  }

  console.log(
    `--------------------------------------------------------------------------------------------------------`
  );
  console.log(targetItem.isFolder);

  console.log(
    `--------------------------------------------------------------------------------------------------------`
  );

  setHoveringItems(null);

  if (targetItem.isFolder === true) {
    const draggedItems = event.dataTransfer.getData("draggedMultipleFiles");
    const draggedItem = event.dataTransfer.getData("draggedSingleFile");

    const droppedOnPath = targetItem.path.replace(/^\//, "");

    console.log(targetItem);

    let cutItemList;

    if (draggedItems) {
      // Dropped multiple files
      const items = JSON.parse(draggedItems);

      console.log("Dropped items:", items);
      console.log("Dropped on:", targetItem);

      cutItemList = items.map((item) => {
        const isFolder = item.isFolder;

        const fileName = item.path.split("/").pop(); // Extract filename
        const newFileName =
          item.path.startsWith("/") && !item.path.slice(1).includes("/")
            ? item.path.slice(1) // Remove leading slash if it's a single-level path
            : item.path; // Leave unchanged if it has directories

        return {
          currentName: isFolder ? `${newFileName}/` : newFileName, // Append `/` if target is a garage
          newName: isFolder
            ? `${droppedOnPath}/` // Append `/` to new path if target is a garage
            : `${droppedOnPath}/${fileName}`, // Normal path otherwise
        };
      });
      console.log("Dropped Item:", cutItemList);

      CutServiceForDragAndDrop(cutItemList, accessToken)
        .then((result) => {
          if (result) {
            handelRefreshAfterAction();

            toast.success("Files moved successfully!", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              closeButton: false,
              style: { color: "green", backgroundColor: "#f4f4f4" },
            });
          } else {
            toast.error("Move failed!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: { color: "#fff", backgroundColor: "#dc3545" },
            });
          }
        })
        .catch((error) => console.log("Eror during move:"))
        .finally(() => {
          dispatch(clearMultipleFileSelect());
        });
    } else if (draggedItem) {
      // Dropped a single file
      const item = JSON.parse(draggedItem);

      const isFolder = item.isFolder;

      const fileName = item.path.split("/").pop();
      console.log("Dropped item:", item);
      console.log("Dropped on:", targetItem);

      const newFileName =
        item.path.startsWith("/") && !item.path.slice(1).includes("/")
          ? item.path.slice(1) // Remove leading slash if it's a single-level path
          : item.path; // Leave unchanged if it has directories

      cutItemList = [
        {
          currentName: isFolder ? `${newFileName}/` : newFileName, // Append `/` if target is a garage
          newName: isFolder
            ? `${droppedOnPath}/` // Append `/` to new path if target is a garage
            : `${droppedOnPath}/${fileName}`, // Normal path otherwise
        },
      ];

      console.log("Dropped Item:", cutItemList);

      CutServiceForDragAndDrop(cutItemList, accessToken)
        .then((result) => {
          if (result) {
            handelRefreshAfterAction();

            toast.success("Files moved successfully!", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              closeButton: false,
              style: { color: "green", backgroundColor: "#f4f4f4" },
            });
          } else {
            toast.error("Move failed!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: { color: "#fff", backgroundColor: "#dc3545" },
            });
          }
        })
        .catch((error) => console.log("Eror during move:"))
        .finally(() => {
          dispatch(clearMultipleFileSelect());
        });
    }
  }
};

export const handleIndividualDragOver = (event, item, index, isDragging, setHoveringItems) => {
  if (isDragging) {
    event.preventDefault();
    event.stopPropagation();
    setHoveringItems(index);
  }

  if (event.dataTransfer.types.includes("Files")) {
    return;
  }
};

export const handleIndividualDragLeave = (setHoveringItems) => {
  setHoveringItems(null);
};

export const handleIndividualDragEnd = (setIsDragging) => {
  setIsDragging(false);
};