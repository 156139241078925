import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  toEmails: [],
  ccEmails: [],
  bccEmails: [],
  emailsValid: false,
};

const emailFilesSlice = createSlice({
  name: 'emailFiles',
  initialState,
  reducers: {
    addEmailToList(state, action) {
      const { field, email } = action.payload;
      const newEmail = email.toLowerCase().trim();
      state[field] = Array.from(new Set([...state[field], newEmail]));
      state.emailsValid = state.toEmails.length > 0;
    },
    clearEmailsList(state) {
      state.toEmails = [];
      state.ccEmails = [];
      state.bccEmails = [];
      state.emailsValid = false;
    },
    removeEmailFromList(state, action) {
      const { field, email } = action.payload;
      state[field] = state[field].filter(e => e !== email.toLowerCase().trim());
      state.emailsValid = state.toEmails.length > 0;
    },
    setEmailValidationError(state) {
      state.emailsValid = false;
    },
    setClearEmailValidation(state) {
      state.emailsValid = true;
    },
    setEmailsList(state, action) {
      const { field, emails } = action.payload;
      state[field] = emails;
      state.emailsValid = state.toEmails.length > 0;
    },
  },
});

export const {
  addEmailToList,
  clearEmailsList,
  removeEmailFromList,
  setEmailValidationError,
  setClearEmailValidation,
  setEmailsList,
} = emailFilesSlice.actions;
export default emailFilesSlice.reducer;