import React, { useEffect, useState } from "react";
import "../newComponent/RenameDialog.css";
import { useTranslation } from 'react-i18next';
import RenameService from "../services/renameService";
import { useDispatch, useSelector } from "react-redux";
import { setFilesStore } from "../store/reducers/filesReducer";

const RenameDialog = ({ 
    accessToken,
    open,
    onClose,
    fileName,
    fileToRename,
    setInitialFileLoading,
    messageApi,
    onRenameComplete
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const storeFiles = useSelector((state) => state.files.files);
  let message = {};

  // Extract only the file name to display in the input field
  const initialFileName = fileName.split("/").pop(); // Get the last part (file name)
  const [newName, setNewName] = useState(initialFileName);

  // Update the newName state whenever fileName prop changes
  useEffect(() => {
    setNewName(initialFileName);
  }, [fileName]);


  const handleRenameSubmit = async (newName) => {
    // Early return if no file or folder is selected
    if (!fileToRename) return;

    // Determine if the item to rename is a folder or file
    const isFolder = fileToRename.isFolder;

    // Split the current path to separate the directory from the item name
    const pathParts = fileToRename.name.split("/");
    const oldName = pathParts.pop(); // Extracts the current item name (last part of path)
    const directoryPath = pathParts.join("/"); // Rejoins the rest as the directory path

    // Define the original path including any necessary trailing slash
    let currentNamePath;
    if (isFolder) {
      // For folders, ensure the path ends with "/"
      currentNamePath = fileToRename.name.endsWith("/")
        ? fileToRename.name
        : `${fileToRename.name}/`;
    } else {
      // For files, keep the path as-is
      currentNamePath = fileToRename.name;
    }

    // Define the new path for the renamed item
    let newFilePath;
    if (isFolder) {
      // For folders, construct the new path with a trailing slash
      if (directoryPath) {
        newFilePath = `${directoryPath}/${newName}/`;
      } else {
        newFilePath = `${newName}/`;
      }
    } else {
      // For files, add the file extension to the new name
      const fileExtension = oldName.slice(oldName.lastIndexOf("."));
      if (directoryPath) {
        newFilePath = `${directoryPath}/${newName}${fileExtension}`;
      } else {
        newFilePath = `${newName}${fileExtension}`;
      }
    }

    // Prepare data payload for the rename request
    const renameData = [{ currentName: currentNamePath, newName: newFilePath }];

    // Call the rename service and handle the response
    try {
      setInitialFileLoading(true);
      const renameResult = await RenameService(renameData, accessToken);
      setInitialFileLoading(false);
      if (renameResult === true) {
        // Show success message to the user
        message = {
          type: "success",
          content: t("RenameSuccessMsg"),
          duration: 3,
        }

        // Update the UI to show the new name immediately if the rename was successful
        const updateName = (item) => {
          if (item.name === fileToRename.name) {
            let updatedName = newFilePath;
            // Remove the trailing slash for folders when updating the name in the UI
            if (isFolder && updatedName.endsWith("/")) {
              updatedName = updatedName.slice(0, -1); // Remove the trailing slash for folders
            }
            return { ...item, name: updatedName }; // Update the item's name
          }
          return item; // Return unchanged items
        };

        dispatch(setFilesStore(storeFiles.map(updateName))); // Update the store
        onRenameComplete(message);
      } else {
        // Show error message if the rename failed
        message = {
          type: "error",
          content: "Rename failed. Try again!",
          duration: 3,
        };
        onRenameComplete(message);
      }
    } catch (error) {
      console.error(error);
      // Show error message if there was an exception during rename
      message = {
        type: "error",
        content: "An error occurred during rename!",
        duration: 3,
      };
      onRenameComplete(message);
    }
  };

  const handleRename = () => {
    // Validate that the input is not empty
    if (!newName.trim()) {
      messageApi.open({
        type: "warning",
        content: t("FieldEmptyMsg"),
        duration: 3,
      });
      return; // Prevent renaming if the field is empty
    }
    handleRenameSubmit(newName); // Send only the new name, without the extension
    setNewName("");
    onClose();
  };

  const handelKeyDown = (event) => {
    if (event.key === "Enter") {
      handleRename();
    } else if (event.key === "Escape") {
      onClose();
    }
  };

  if (!open) return null; // Only render the dialog if it's open

  return (
    <div className="dialog-overlay">
      <div id="renameModal" className="dialog-container">
        <div id="renameHeading" className="dialog-title">{t('Rename')}</div>
        <div className="dialog-content">
          <input
            autoFocus
            id="renameInput"
            type="text"
            className="dialog-input"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            placeholder={t("NewName")}
            onKeyDown={handelKeyDown}
          />
        </div>
        <div className="dialog-actions">
          <button id="renameCancel" onClick={onClose} className="dialog-button">
            {t('Cancel')}
          </button>
          <button
            id="renameOk"
            onClick={handleRename}
            className="dialog-button dialog-button-confirm"
          >
            {t('OK')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RenameDialog;
