export const reapplySorting = (items, sortKey, ascending, isTrashResults, isRecentResults, isFavoritesResults) => {
    const files = items.filter((item) => !item.isFolder);
    const folders = items.filter((item) => item.isFolder);

    const sortedFiles = files.sort((a, b) => {
        let valA, valB;
        if (sortKey === "name") {
            valA = a.name.toLowerCase();
            valB = b.name.toLowerCase();
        } else if (sortKey === "date") {
            if (isTrashResults) {
                valA = new Date(a.deletedTime);
                valB = new Date(b.deletedTime);
            } else if (isRecentResults) {
                valA = new Date(a.lastAccessedTime);
                valB = new Date(b.lastAccessedTime);
            } else if (isFavoritesResults) {
                valA = new Date(a.lastModifiedTime);
                valB = new Date(b.lastModifiedTime);
            } else {
                valA = new Date(a.lastModifiedDate);
                valB = new Date(b.lastModifiedDate);
            }
        } else if (sortKey === "size") {
            valA = a.size || 0;
            valB = b.size || 0;
        }
        if (valA < valB) return ascending ? -1 : 1;
        if (valA > valB) return ascending ? 1 : -1;
        return 0;
    });

    const sortedFolders = folders.sort((a, b) => {
        let valA, valB;
        if (sortKey === "name") {
            valA = a.name.toLowerCase();
            valB = b.name.toLowerCase();
        } else if (sortKey === "date") {
            valA = new Date(a.lastModifiedTime);
            valB = new Date(b.lastModifiedTime);
        } else if (sortKey === "size") {
            valA = a.size || 0;
            valB = b.size || 0;
        }
        if (valA < valB) return ascending ? -1 : 1;
        if (valA > valB) return ascending ? 1 : -1;
        return 0;
    });

    return [...sortedFiles, ...sortedFolders];
};

// Helper function to parse the date string into a Date object
/*
const extractMonth = (dateString) => {
    if (!dateString) return null;

    const [datePart] = dateString.split(" "); // Only take the date part
    const [month, day, year] = datePart.split("/").map(Number);

    return month && day && year ? new Date(year, month - 1, day) : null;
};
*/