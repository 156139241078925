import { useState } from "react";

import "../screens/LoginScreen.css";

// import applogo1 from "../assets/logo5.jpg";

import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth, db } from "../config/firebase";
import { useNavigate } from "react-router-dom";
import SignInWithGoogle from "../components/SignInWithGoogle";
import {
  COMPANY_LOGO,
  COMPANY_LOGO_RECTANGLE,
} from "../components/APP_LOGO/app_logo";
import { doc, getDoc, setDoc } from "firebase/firestore";
import SignInWithFaceBook from "../components/SignInWithFaceBook";
import FacebookLoginButton from "../components/SignInWithFaceBook";
import SignInWithTwitter from "../components/SignInWithTwitter";

export default function LoginScreen({ setAccessToken }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const navigate = useNavigate();

  function emailHandler(e) {
    setEmail(e.target.value);
  }

  function passwordHandler(e) {
    setPassword(e.target.value);
  }

  // async function handelSubmit(e) {
  //   e.preventDefault();

  //   setMessage("");
  //   try {
  //     await signInWithEmailAndPassword(auth, email, password);
  //     setMessage("Login successful!");
  //     setMessageType("success");
  //     navigate("/home");
  //   } catch (error) {
  //     setMessage("Incorrect email or password");
  //     setMessageType("error");
  //   }
  // }

  async function handelSubmit(e) {
    e.preventDefault();

    setMessage("");

    try {
      const userDataForSignInWithEmailandPassword =
        await signInWithEmailAndPassword(auth, email, password);

      const user = userDataForSignInWithEmailandPassword.user;

      const emailToken = await user.getIdToken();

      localStorage.setItem("accessToken", emailToken);

      if (user) {
        const docRef = doc(db, "Users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const savedToken = localStorage.getItem("accessToken");

          console.log(`SAVED TOKEN IN SEND TO ALL PAGES: ${savedToken}`);

          // User data exists
          navigate("/home", {
            state: { userDetail: docSnap.data(), accessToken: savedToken },
          });
        } else {
          // If no user data, create a new document
          await setDoc(docRef, {
            email: user.email,
            name: user.displayName,
            lastLogin: new Date(),
            profilePicture: user.photoURL,
          });

          const savedToken = localStorage.getItem("accessToken");
          navigate("/home", {
            state: {
              userDetail: {
                email: user.email,
                name: user.displayName,
                profilePicture: user.photoURL,
              },
              accessToken: savedToken,
            },
          });
        }
      }
    } catch (error) {
      setMessage("Incorrect email or password");
      setMessageType("error");
    }

    // try {
    //   await signOut(auth);
    //   setAccessToken(null);
    //   localStorage.removeItem("accessToken"); // Remove token from localStorage
    //   await signInWithEmailAndPassword(auth, email, password);
    //   setMessage("Login successful!");
    //   setMessageType("success");
    //   navigate("/home");
    // } catch (error) {
    //   setMessage("Incorrect email or password");
    //   setMessageType("error");
    // }
  }

  const handelLogoClick = () => {
    navigate("/");
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <div className="logo-container">
          {/* <img
            src="https://ctzeusbackenduatlogo.blob.core.windows.net/uatlogo/ez-byte-logo.png"
            alt="appLogo"
            className="login-logo"
            onClick={handelLogoClick}
            style={{
              cursor: "pointer",
            }}
          /> */}
          <img
            src={COMPANY_LOGO_RECTANGLE}
            alt="appLogo"
            className="login-logo"
            onClick={handelLogoClick}
            style={{
              cursor: "pointer",
            }}
          />
        </div>
        <form className="login-form" onSubmit={handelSubmit}>
          <input
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={emailHandler}
            className="login-input"
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={passwordHandler}
            className="login-input"
            required
          />
          <button type="submit" className="login-button">
            Submit
          </button>
        </form>
        {message && <p className={`message ${messageType}`}>{message}</p>}
        {/* <p className="para-login">
          New user? <a href="/register">Register Here</a>
        </p> */}
        <SignInWithGoogle />

        <SignInWithFaceBook />

        <SignInWithTwitter />

        {/* <FacebookLoginButton /> */}
      </div>
    </div>
  );
}
