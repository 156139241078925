import axios from "axios";

const ALL_PREFERENCE_OPTIONS_URL =
  process.env.REACT_APP_GET_ALL_PREFERENCE_OPRION_API_URL;

export const getAllPreferenceOptionService = async (accessToken) => {
  try {
    const response = await axios.get(ALL_PREFERENCE_OPTIONS_URL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response;
  } catch (error) {
    console.log("Error fetching preferencve options: ", error);
    throw error;
  }
};
