import React, { useEffect } from 'react';
import "./CommonOverlay.css";
import { useTranslation } from 'react-i18next';

const CommonOverlay = ({ isOpen, onClose, children,  title }) => {
     const { t } = useTranslation();
    useEffect(() => {
        const handelKeyDown = (event) => {
            if (event.key === "Escape") {
                onClose();
            }
        };

        if (isOpen) {
            document.body.style.overflow = "hidden"; // Disable background scrolling
            window.addEventListener("keydown", handelKeyDown);
        } else {
            document.body.style.overflow = "auto"; // Re-enable background scrolling
        }

        return () => {
            document.body.style.overflow = "auto"; // Cleanup when the component unmounts
            window.removeEventListener("keydown", handelKeyDown);
        };
    }, [isOpen, onClose]);

    if (!isOpen) return null;

    return (
        <div id="commonModal" className="common-overlay {`overlay-${size}`}">
            <div
                
                className={
                    title === "Email"
                        ? "email-overlay-content"
                        : title === "Feedback" ? "feedback-overlay-content" :
                            title === "Upload" ? "upload-overlay-content" : "preview-overlay-content"
                }
            >
                <span id="commonModalHeading" className="common-heading">{t(title)}</span>
                <button id="commonModalClose" className="common-close-button" onClick={onClose}>
                    X
                </button>
                {children}
            </div>
        </div>
    );
}


export default CommonOverlay;
