import DownloadService from "../services/downloadService";
import DeleteService from "../services/deleteService";
import CutService from "../services/cutService";
import CopyService from "../services/copyService";
import Restore from "../services/restoreService";
import FavoritesCreate from "../newComponent/FavoritesCreate";
import FavoritesDelete from "../newComponent/FavoritesDelete";
import { 
    removeItemFromCurrentItems, 
    setFilesStore, 
    removeFiles, 
    updateFiles, 
    updateCurrentItems, 
    setMultipleFileSelect,
    clearMultipleFileSelect 
} from "../store/reducers/filesReducer";

const contextMenuOptionClick = (
    dispatch,
    option,
    currentItems,
    selectedFiles,
    accessToken,
    handelRefreshAfterAction,
    messageApi,
    t,
    setIsFilePreviewOverlayOpen,
    setSelectedFilesToEmail,
    setIsEmailOverlayOpen,
    setFileToRename,
    setIsRenameDialogOpen,
    setSelectedFileForNewFolder,
    setIsCreateFolderModalOpen,
    setCopiedPaths,
    setOperationType,
    setCutPaths,
    setIsMessageProcessing,
    isMessageProcessing,
    currentFolderPath,
    storeFiles,
    fetchFilesData,
    isFavoritesResults,
    isRecentResults,
    isSearchResults,
    handleFilePreviewClick,
    setIsDownloading,
    setProgress,
    setDownloadStatus,
    setNumberOfDownloadedFiles,
    toast,
    operationType,
    cutPaths,
    copiedPaths
) => {
    switch (option) {
        case "Preview":
            if (selectedFiles.length === 1) {
                const fileToPreview = selectedFiles[0];
                const index = currentItems.findIndex(
                    (item) => item.name === fileToPreview.name
                );
                handleFilePreviewClick(
                    fileToPreview.name,
                    fileToPreview.path,
                    index,
                    currentItems
                );
                setIsFilePreviewOverlayOpen(true);
            }

            dispatch(clearMultipleFileSelect());

            break;

        case "Download":
            if (selectedFiles.length >= 1) {
                const processedFiles = selectedFiles.map((file) => {
                    if (file.isFolder && !file.path.endsWith("/")) {
                        return { ...file, path: `${file.path}/` };
                    }
                    return {
                        ...file,
                        path: file.path.startsWith("/") ? file.path.slice(1) : file.path,
                    };
                });

                setIsDownloading(true);
                setProgress(0);

                if (selectedFiles.length === 1 && selectedFiles[0].isFolder === false) {
                    setDownloadStatus(t("DownloadingMsg"));
                    setNumberOfDownloadedFiles("");
                } else {
                    setDownloadStatus(t("DownloadingMsg"));

                    setNumberOfDownloadedFiles(`zipping (${selectedFiles.length}) files`);
                }

                // Start simulated progress
                let simulatedProgress = 0;
                const progressInterval = setInterval(() => {
                    // simulatedProgress += 10; // Increment progress by 10%
                    simulatedProgress += selectedFiles.length === 1 ? 20 : 10; // Increment progress by 10%
                    if (simulatedProgress < 100) {
                        setProgress(simulatedProgress);
                    }
                }, 200); // Update progress every 200ms

                DownloadService(processedFiles, accessToken)
                    .then((downloadResult) => {
                        if (downloadResult) {
                            console.log("Download successful");

                            // Complete the progress
                            setProgress(100);
                            setDownloadStatus(`${selectedFiles.length} files zipped`);
                            clearInterval(progressInterval);

                            // Create a blob URL from the response
                            const blob = new Blob([downloadResult], {
                                type: "application/octet-stream", // Adjust MIME type if necessary
                            });
                            const downloadUrl = window.URL.createObjectURL(blob);

                            // Create a temporary link element to trigger the download
                            const link = document.createElement("a");
                            link.href = downloadUrl;

                            console.log(selectedFiles);

                            if (
                                selectedFiles.length === 1 &&
                                selectedFiles[0].isFolder === false
                            ) {
                                link.setAttribute("download", selectedFiles[0].name); // Use the first file's name
                            } else {
                                link.setAttribute("download", "ezebyte-Downloaded.zip"); // Name it generically if multiple files are downloaded
                            }

                            document.body.appendChild(link);
                            link.click();

                            // Clean up by removing the link and revoking the blob URL
                            link.parentNode.removeChild(link);
                            window.URL.revokeObjectURL(downloadUrl);
                        } else {
                            console.log("Download failed");
                        }
                    })
                    .catch((error) => {
                        console.log(`Error during download:`, error);
                        setDownloadStatus("Error during download");
                        clearInterval(progressInterval);
                    })
                    .finally(() => {
                        setIsDownloading(false);
                        setNumberOfDownloadedFiles("");
                    });
            }

            dispatch(clearMultipleFileSelect());
            break;

        case "Email":
            if (selectedFiles.length >= 1) {
                setSelectedFilesToEmail(selectedFiles);
                setIsEmailOverlayOpen(true);
                dispatch(clearMultipleFileSelect());
            }
            break;

        case "Rename":
            if (selectedFiles.length === 1) {
                setFileToRename(selectedFiles[0]);
                setIsRenameDialogOpen(true); // Open the rename dialog
                dispatch(clearMultipleFileSelect());
            }
            break;

        case "Folder":
            setSelectedFileForNewFolder(selectedFiles);
            setIsCreateFolderModalOpen(true);
            break;

        case "Copy":
            console.log(selectedFiles);
            const pathsToCopy = selectedFiles.map((file) => ({
                currentName: file.isFolder
                    ? file.path.endsWith("/")
                        ? file.path
                        : `${file.path}/` // Ensure trailing slash for folders
                    : file.path.replace(/\/$/, ""), // Ensure no trailing slash for files
            }));
            setCopiedPaths(pathsToCopy);
            console.log("Copied Paths:", pathsToCopy);
            setOperationType("copy");
            dispatch(clearMultipleFileSelect());
            break;

        case "Paste":
            console.log(`CURRENT FOLDER PATH`);
            console.log(currentFolderPath);

            if (isMessageProcessing) return;
            setIsMessageProcessing(true);

            // Check if operation type is cut
            if (operationType === "cut" && cutPaths.length > 0) {
                const targetFolderPath = currentFolderPath.endsWith("/")
                    ? currentFolderPath
                    : `${currentFolderPath}/`;

                const cutItemList = cutPaths.map((item) => {
                    const cleanCurrentName = item.currentName.replace(/\/$/, ""); // Clean trailing slash if any
                    const fileName = cleanCurrentName.split("/").pop(); // Extract filename

                    return {
                        currentName: item.currentName,
                        newName: `${targetFolderPath}${fileName}${item.currentName.endsWith("/") ? "/" : ""
                            }`,
                    };
                });

                console.log("Constructed cutItemList:", cutItemList);

                CutService({ cutItemList }, accessToken, selectedFiles)
                    .then((result) => {
                        if (result) {
                            console.log("Files moved successfully");

                            handelRefreshAfterAction();

                            toast.success(t("CutSuccessMsg"), {
                                position: "top-center",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                closeButton: false,
                                style: { color: "green", backgroundColor: "#f4f4f4" },
                            });
                            setIsMessageProcessing(false);
                        } else {
                            console.log("Move failed");
                            toast.error("Move failed!", {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                style: { color: "#fff", backgroundColor: "#dc3545" },
                            });
                            setIsMessageProcessing(false);
                        }
                    })
                    .catch((error) => console.error("Error during move:", error))
                    .finally(() => {
                        setCutPaths([]); // Clear cutPaths after paste
                        setOperationType(""); // Reset operation type
                        dispatch(clearMultipleFileSelect());
                        setIsMessageProcessing(false);
                    });
            }

            // Check if operation type is copy
            else if (operationType === "copy" && copiedPaths.length > 0) {
                const targetFolderPath = currentFolderPath.endsWith("/")
                    ? currentFolderPath
                    : `${currentFolderPath}/`;

                const copyItemList = copiedPaths.map((item) => {
                    const cleanCurrentName = item.currentName.replace(/\/$/, ""); // Clean trailing slash if any
                    const fileName = cleanCurrentName.split("/").pop(); // Extract filename

                    return {
                        currentName: item.currentName,
                        newName: `${targetFolderPath}${fileName}${item.currentName.endsWith("/") ? "/" : ""
                            }`,
                    };
                });

                console.log("Constructed copyItemList:", copyItemList);

                CopyService({ copyItemList }, accessToken, selectedFiles)
                    .then((result) => {
                        if (result) {
                            console.log("Files pasted successfully");
                            handelRefreshAfterAction();
                            toast.success(t("PasteSuccessMsg"), {
                                position: "top-center",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                closeButton: false,
                                style: { color: "green", backgroundColor: "#f4f4f4" },
                            });

                            setIsMessageProcessing(false);
                        } else {
                            console.log("Paste failed");
                            toast.error("Paste failed!", {
                                position: "top-center",
                                autoClose: 2000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                style: { color: "#fff", backgroundColor: "#dc3545" },
                            });
                        }
                        setIsMessageProcessing(false);
                    })
                    .catch((error) => console.error("Error during paste:", error))
                    .finally(() => {
                        setCopiedPaths([]); // Clear copiedPaths after paste
                        setOperationType(""); // Reset operation type
                        dispatch(clearMultipleFileSelect());
                        setIsMessageProcessing(false);
                    });
            }

            break;

        case "Cut":
            console.log(selectedFiles.map((item) => item.name));

            // setCutPaths([...selectedFiles]);

            const pathsToCut = selectedFiles.map((file) => ({
                currentName: file.isFolder
                    ? file.path.endsWith("/")
                        ? file.path
                        : `${file.path}/` // Ensure trailing slash for folders
                    : file.path.replace(/\/$/, ""), // Ensure no trailing slash for files
            }));
            // setCopiedPaths(pathsToCopy);
            setCutPaths(pathsToCut);
            console.log("Cut Paths:", pathsToCut);
            setOperationType("cut");

            dispatch(clearMultipleFileSelect());
            break;

        case "Restore":
            if (selectedFiles.length >= 1) {
                Restore(accessToken, selectedFiles)
                    .then((_restoreResult) => {
                        if (_restoreResult.result === true) {
                            selectedFiles.map((selectedItem) => dispatch(removeItemFromCurrentItems(selectedItem["path"])));
                            const s = selectedFiles.map((item) => {
                                return {
                                    ...item,
                                    favorite: "false",
                                    path: item.path,
                                    name: item.path,
                                };
                            });
                            dispatch(setFilesStore([...storeFiles, ...s]));

                            dispatch(clearMultipleFileSelect());
                            messageApi.open({
                                type: "success",
                                content: t("RestoreSuccessMsg"),
                                duration: 3,
                            });
                            fetchFilesData();
                        } else {
                            messageApi.open({
                                type: "error",
                                content: "File not restored. Please try again!",
                                duration: 3,
                            });
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
            break;

        case "AddToFavorite":

            FavoritesCreate(accessToken, selectedFiles)
                .then((_favoritesCreate) => {
                    if (_favoritesCreate.result === true) {
                        const updatedFiles = selectedFiles.map((file) => {
                            return { ...file, favorite: "true" }
                        });

                        dispatch(updateFiles(updatedFiles));
                        dispatch(updateCurrentItems(updatedFiles));

                        dispatch(clearMultipleFileSelect());
                        messageApi.open({
                            type: "success",
                            content: t("FavoritesAddedMsg"),
                            duration: 3,
                        });
                    } else {
                        messageApi.open({
                            type: "error",
                            content: "File(s) not added Favorites. Please try again!",
                            duration: 3,
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                });

            break;

        case "RemoveFromFavorite":
            FavoritesDelete(accessToken, selectedFiles)
                .then((_favoritesDelete) => {
                    if (_favoritesDelete.result === true) {
                        const updatedFile = selectedFiles.map((file) => {
                            return { ...file, favorite: "false" }
                        });

                        dispatch(updateFiles(updatedFile));
                        console.log("sudha", updatedFile);
                        updatedFile.forEach((file) =>{
                        dispatch(removeItemFromCurrentItems(file.path))
                    });

                        dispatch(clearMultipleFileSelect());
                        messageApi.open({
                            type: "success",
                            content: t("FavoritesRemoveMsg"),
                            duration: 3,
                        });
                    } else {
                        messageApi.open({
                            type: "error",
                            content: "File(s) not removed from Favorites. Please try again!",
                            duration: 3,
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
            break;

        case "Delete":
            if (isMessageProcessing) return;
            setIsMessageProcessing(true);

            if (selectedFiles.length >= 1) {
                DeleteService(selectedFiles, accessToken)
                    .then((_deleteResult) => {
                        if (_deleteResult === true) {
                            selectedFiles.map((selectedItem) =>
                                dispatch(removeItemFromCurrentItems(selectedItem["path"])));

                            dispatch(removeFiles(selectedFiles));
                            //fetchFilesData();
                            dispatch(clearMultipleFileSelect());
                            if (
                                isFavoritesResults === false &&
                                isRecentResults === false &&
                                isSearchResults === false
                            ) {
                                handelRefreshAfterAction();
                            }
                            messageApi.open({
                                type: "success",
                                content: t("DeleteSuccessMsg"),
                                duration: 3,
                            });
                            setIsMessageProcessing(false);
                        } else {
                            messageApi.open({
                                type: "error",
                                content: "File(s) not deleted. Please try again!",
                                duration: 3,
                            });
                            setIsMessageProcessing(false);
                        }
                        console.log(_deleteResult);
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => {
                        setIsMessageProcessing(false);
                    });
            }
            break;
        default:
            break;
    }
};

const positionContextMenu = (
    event,
    contextMenuElement,
    setContextMenu,
    options
) => {
    setContextMenu({ visible: true, x: -9999, y: -9999, options });
    setTimeout(() => {
        const contextMenu = document.querySelector(contextMenuElement);
        if (contextMenu) {
            const contextMenuRect = contextMenu.getBoundingClientRect();
            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;
            const padding = 30;
            let x = event.clientX;
            let y = event.clientY;
            if (x + contextMenuRect.width + padding > windowWidth) {
                x = windowWidth - contextMenuRect.width - padding;
            }
            if (x < padding) {
                x = padding;
            }
            if (y + contextMenuRect.height + padding > windowHeight) {
                y = windowHeight - contextMenuRect.height - padding;
            }
            if (y < padding) {
                y = padding;
            }
            setContextMenu({ visible: true, x, y, options });
        }
    }, 0);
};

const ContextMenuOptionsBuilder = (
    file,
    multipleFileSelect,
    dispatch,
    isTrashResults,
    isSearchResults,
    isRecentResults,
    isFavoritesResults,
    copiedPaths,
    cutPaths,
    t
) => {

    const isFileSelected = multipleFileSelect.some(
        (item) => item.name === file.name
    );
    let newSelection = [];
    if (!isFileSelected) {
        newSelection = [
            {
                name: file.name,
                size: file.size,
                path: file.path,
                isFolder: file.isFolder,
                createdDate: file.createdDate,
                favorite: file.favorite,
                lastModifiedDate: file.lastModifiedTime,
            },
        ];
        dispatch(setMultipleFileSelect(newSelection));
    } else {
        newSelection = [...multipleFileSelect];
    }
    let options;
    if (isTrashResults === true) {
        options = [t("Restore")];
    } else if (isSearchResults === true) {
        options = [
            ...(newSelection.length > 1 ? [] : ["Preview"]),
            "Download",
            "Email",
            "Rename",
            "Copy",
            "Cut",
            "Delete",
        ];
    } else if (isRecentResults === true) {
        options = [
            ...(newSelection.length > 1 ? [] : ["Preview"]),
            "Delete",
            "Email",
            "Download",
        ];
    } else if (isFavoritesResults === true) {
        options = [
            ...(newSelection.length > 1 ? [] : ["Preview"]),
            "Delete",
            ...(newSelection.length > 1 ? [] : ["RemoveFromFavorite"]),
            "Email",
            "Download",
        ];
    } else if (newSelection.length === 1) {
        let favoriteOption =
            file.favorite === "true" ? "RemoveFromFavorite" : "AddToFavorite";
        options = [
            ...(copiedPaths.length > 0 || cutPaths.length > 0 ? ["Paste"] : []),
            "Preview",
            "Rename",
            "Delete",
            favoriteOption,
            "Email",
            "Download",
            "Cut",
            "Copy",
            "Folder",
        ];
    } else if (newSelection.length > 1) {
        options = ["Delete", "Email", "Download", "Cut", "Copy", "Folder"];
    }
    const folderItems = newSelection.filter((f) => f.isFolder === true);
    if (folderItems.length > 0) {
        if (folderItems.length === 1 && newSelection.length === 1) {
            options = [
                ...(copiedPaths.length > 0 || cutPaths.length > 0 ? ["Paste"] : []),
                "Rename",
                "Delete",
                "Download",
                "Cut",
                "Copy",
                "Folder",
            ];
        } else if (folderItems.length === newSelection.length) {
            options = ["Delete", "Download", "Cut", "Copy", "Folder"];
        } else {
            options = ["Delete", "Email", "Download", "Cut", "Copy"];
        }
    }

    return options;
};

export {
    contextMenuOptionClick,
    positionContextMenu,
    ContextMenuOptionsBuilder,
};
