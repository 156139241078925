import React from "react";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import EmailIcon from "@mui/icons-material/Email";
import DownloadIcon from "@mui/icons-material/Download";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { useSelector } from "react-redux";
import {
  ContentPasteOutlined as ContentPasteOutlinedIcon,
  RestoreFromTrash,
} from "@mui/icons-material";

const optionsIcons = {
  Preview: <VisibilityIcon />,
  Download: <DownloadIcon />,
  Email: <EmailIcon />,
  Rename: <ModeEditIcon />,
  Copy: <ContentCopyIcon />,
  Cut: <ContentCutIcon />,
  Delete: <DeleteIcon />,
  AddToFavorite: <BookmarkBorderOutlinedIcon />,
  RemoveFromFavorite: <BookmarkIcon />,
  Paste: <ContentPasteOutlinedIcon />,
  Folder: <CreateNewFolderIcon />,
  Restore: <RestoreFromTrash />,
};

const ContextMenu = ({
  contextMenu,
  contextMenuIndex,
  handleContextMenuOptionClick,
  t,
  isCreateFolderModalOpen,
  isRenameDialogOpen,
  isFilePreviewOverlayOpen,
}) => {
  const multipleFileSelect = useSelector((state) => state.files.multipleFileSelect);

  if (
    !contextMenu.visible ||
    isCreateFolderModalOpen ||
    isRenameDialogOpen ||
    isFilePreviewOverlayOpen
  ) {
    return null;
  }

  return (
    <ul
      id="file-actions-context-menu"
      className="context-menu"
      style={{
        top: contextMenu.y,
        left: contextMenu.x,
      }}
    >
      {contextMenu.options.map((option, idx) => (
        <React.Fragment key={idx}>
          <li
            className={contextMenuIndex === idx ? "highlighted-option" : ""}
            onClick={() =>
              handleContextMenuOptionClick(option, multipleFileSelect)
            }
            style={{
              marginTop:
                option === "Download" || option === "Cut" || option === "Delete"
                  ? "10px"
                  : "0",

              marginBottom: contextMenu.options.length === 1 ? "0px" : "",
            }}
          >
            <span style={{ marginRight: "8px" }}>{optionsIcons[option]}</span>
            {t(option) === "AddToFavorite"
              ? "Add to favorite"
              : t(option) === "RemoveFromFavorite"
              ? "Remove from favorite"
              : t(option)}
          </li>
          {["Paste", "Rename", "Delete", "Download"].includes(option) && (
            <hr
              style={{
                border: "none",
                borderBottom: "0.5px solid rgb(211, 211, 211)",
                margin: "5px 0",
              }}
            />
          )}
        </React.Fragment>
      ))}
    </ul>
  );
};

export default ContextMenu;
