import React from "react";
import { Tooltip } from "antd";
import { Folder as FolderIcon } from "@mui/icons-material";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { lightBlue } from "@mui/material/colors";
import fileIcons from "./FileIcons";
import moment from "moment-timezone";
import UserInitials from "./UserInitials";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";

const formatDateTime = (dateTime) => {
  const userTimeZone = moment.tz.guess();
  return moment(dateTime).tz(userTimeZone).format("MMM DD, YYYY hh:mm:ss A");
};

const getFileIcon = (fileName) => {
  const extension = fileName.split(".").pop().toLowerCase();
  return fileIcons[extension] || fileIcons.default;
};

const extractFileName = (filePath) => {
  return filePath.split("/").pop();
};

const DocumentGrid = ({
  currentItems,
  dragActive,
  handelSingleFileClick,
  handleRightClick,
  handleFolderClick,
  handleFilePreviewClick,
  hoveringItems,
  handleIndividualDragStart,
  handleIndividualDragOver,
  handleIndividualDrop,
  handleIndividualDragLeave,
  handleIndividualDragEnd,
  setHoveringItems,
  ownerName,
  ownerLogo,
  ownerEmail,
}) => {
  const { t } = useTranslation();
  const multipleFileSelect = useSelector((state) => state.files.multipleFileSelect);
   const {
      isSearchResults, 
      isRecentResults, 
      isFavoritesResults, 
      isTrashResults 
    } = useSelector((state) => state.pageInfo);

  return (
    <>
      {currentItems.map((item, index) => (
        <div
          key={index}
          id={
            item.isFolder
              ? `documentTableRow_${index}_Folder`
              : `documentTableRow_${index}_File`
          }
          data={`isFavorite:${!item.isFolder && (item.favorite === "true" ? "true" : "false")
            }`}
          className={`details-Row${multipleFileSelect.some((file) => file.path === item.path)
            ? " selected-file"
            : ""
            } ${dragActive ? "drag-active3" : ""}`}
          onClick={(event) => handelSingleFileClick(event, item, index)}
          onContextMenu={(event) => handleRightClick(event, item)}
          onDoubleClick={() => {
            if (item.isFolder) {
              handleFolderClick(item.path);
            } else if (!isTrashResults) {
              const index = currentItems.findIndex(
                (_item) => _item.name === item.name
              );
              handleFilePreviewClick(item.name, item.path, index, currentItems);
            }
          }}
          style={{
            border:
              hoveringItems === index ? "1px solid blue" : "1px solid white",
            borderRadius: hoveringItems === index ? "5px" : "0px",
          }}
        >
          <p
            onDragStart={(event) => handleIndividualDragStart(event, item)}
            onDragOver={(event) => handleIndividualDragOver(event, item, index)}
            onDrop={(event) => handleIndividualDrop(event, item)}
            onDragLeave={handleIndividualDragLeave}
            onDragEnd={handleIndividualDragEnd}
            draggable
            className="name-field-container"
            style={{
              cursor: "pointer",
              paddingLeft: "8px",
              paddingRight: "0px",
              headingWidth: "38%",
              minWidth: "220px",
              marginLeft: "0px",
              paddingLeft: "0px",
            }}
          >
            {item.isFolder ? (
              <FolderIcon
                className="folder-icon"
                style={{
                  width: "30px",
                  color: "fdb900",
                }}
              />
            ) : (
              <img
                draggable="false"
                src={getFileIcon(item.name)}
                alt="file icon"
                className="file-type-icon"
              />
            )}
            <Tooltip
              overlayClassName="item-name-tooltip"
              arrow={false}
              title={extractFileName(item.name)}
            >
              <span
                className="name-field"
                style={{ marginLeft: "8px", marginRight: "8px" }}
              >
                {extractFileName(item.name)}
              </span>
            </Tooltip>

            {!item.isFolder &&
              (item.favorite === "true" ? (
                <BookmarkIcon
                  className="bookMark-filled-icon"
                  fontSize="small"
                  sx={{ color: lightBlue[500] }}
                />
              ) : null)}
          </p>
          <p
            className="owner-column"
            style={{
              minWidth: "180px",
              width: "13%",
            }}>
            {(!item.owner || item.owner.trim() === "" || item.owner.trim() === ownerEmail) ? (
              <>
               <img
                  src={ownerLogo}
                  alt="Owner Logo"
                  className="owner-logo"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/static/media/alterprofile.269aeae61bf344d75935.png'; // Replace with the path to your fallback image
                  }}
                />
                <span>{t("You")}</span>
              </>
            ) : (
              <>
                <UserInitials name={item.owner} />
              </>
            )}
          </p>
          <p
            className="created-date-field"
            style={{
              minWidth: "180px",
              width: "13%",
            }}
          >
            <span style={{ marginLeft: "10px" }}></span>
            {isTrashResults
              ? formatDateTime(item.deletedTime)
              : isRecentResults
                ? formatDateTime(item.lastAccessedTime)
                : item.isFolder
                  ? "-"
                  : isFavoritesResults
                    ? formatDateTime(item.lastModifiedTime)
                    : isSearchResults
                      ? formatDateTime(item.lastModifiedTime)
                      : formatDateTime(item.lastModifiedDate)}
          </p>
          <p
            className="size-field"
            style={{
              minWidth: "110px",
              width: "8%",
            }}
          >
            <span style={{ marginLeft: "10px" }}></span>
            {item.isFolder ? "-" : `${item.size}KB`}
          </p>

          {(isSearchResults ||
            isRecentResults ||
            isFavoritesResults ||
            isTrashResults) && (
              <p
                className="location-field-container"
                style={{
                  cursor: "pointer",
                  minWidth: "110px",
                  width: "28%",
                }}
                onClick={(e) => {
                  if (!isTrashResults) {
                    handleFolderClick(
                      item.path.substring(0, item.path.lastIndexOf("/")) == ""
                        ? "Home"
                        : item.path.substring(0, item.path.lastIndexOf("/")),
                      e
                    );
                  }
                }}
              >
                <span style={{ marginLeft: "10px" }}></span>
                <Tooltip
                  className="location-field"
                  overlayClassName="item-name-tooltip"
                  arrow={false}
                  title={`Home/${item.path.substring(
                    0,
                    item.path.lastIndexOf("/")
                  )}`}
                >
                  {item.isFolder ? "-" : item.location ? item.location : "Home"}
                </Tooltip>
              </p>
            )}
        </div>
      ))}
    </>
  );
};

export default DocumentGrid;
