import {
  FacebookAuthProvider,
  fetchSignInMethodsForEmail,
  GoogleAuthProvider,
  linkWithCredential,
  signInWithPopup,
} from "firebase/auth";

import googleImg from "../assets/google-logo.png";
import "../components/SignInWithGoogle.css";
// import { auth, db } from "./firebase";
import { auth, db } from "../config/firebase";
import { useNavigate } from "react-router-dom";
import { doc, getDoc, setDoc } from "firebase/firestore";

// export default function SignInWithGoogle() {
//   const navigate = useNavigate();
//   async function googleLogin() {
//     const provider = new GoogleAuthProvider();
//     try {
//       const result = await signInWithPopup(auth, provider);
//       const user = result.user;

//       if (user) {
//         // Storing user data into firestore

//         await setDoc(doc(db, "Users", user.uid), {
//           email: user.email,
//           name: user.displayName,
//           lastLogin: new Date(),
//           profilePicture: user.photoURL,
//         });

//         navigate("/home");
//       }
//     } catch (error) {
//       console.error("Error during Google login:", error);
//     }
//   }

//   return (
//     <div className="google-login-container">
//       <p className="or-text">---or continue with---</p>
//       <button className="google-login-button" onClick={googleLogin}>
//         <img src={googleImg} alt="Google Logo" className="google-logo" />
//         <span className="google-login-text">Sign in with Google</span>
//       </button>
//     </div>
//   );
// }
// ----------------------------------------------------

export default function SignInWithGoogle() {
  const navigate = useNavigate();

  async function googleLogin() {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const accessToken = await user.getIdToken(); // Fetch the token

      localStorage.setItem("accessToken", accessToken);

      console.log(`ACCESS TOKEN: ${accessToken}`);
      if (user) {
        const docRef = doc(db, "Users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const savedToken = localStorage.getItem("accessToken");

          console.log(`SAVED TOKEN IN SEND TO ALL PAGES: ${savedToken}`);

          console.log(docSnap.data());
          // User data exists
          navigate("/home", {
            state: { userDetail: docSnap.data(), accessToken: savedToken },
          });
        } else {
          // If no user data, create a new document
          await setDoc(docRef, {
            email: user.email,
            name: user.displayName,
            lastLogin: new Date(),
            profilePicture: user.photoURL,
          });

          const savedToken = localStorage.getItem("accessToken");
          navigate("/home", {
            state: {
              userDetail: {
                email: user.email,
                name: user.displayName,
                profilePicture: user.photoURL,
              },
              accessToken: savedToken,
            },
          });
        }
      }
    } catch (error) {
      console.error("Error during Google login:", error);
    }
  }

  return (
    <div className="google-login-container">
      <p className="or-text">---or continue with---</p>
      <button className="google-login-button" onClick={googleLogin}>
        <img src={googleImg} alt="Google Logo" className="google-logo" />
        <span className="google-login-text">Sign in with Google</span>
      </button>
    </div>
  );
}

// const getProvider = (providerId) => {
//   switch (providerId) {
//     case GoogleAuthProvider.PROVIDER_ID:
//       return new GoogleAuthProvider();
//     case FacebookAuthProvider.PROVIDER_ID:
//       return new FacebookAuthProvider();
//     default:
//       throw new Error(`No provider implemented for ${providerId}`);
//   }
// };

// export default function SignInWithGoogle() {
//   const navigate = useNavigate();

//   async function googleLogin() {
//     const provider = new GoogleAuthProvider();

//     try {
//       const result = await signInWithPopup(auth, provider);
//       const user = result.user;
//       const accessToken = await user.getIdToken();

//       localStorage.setItem("accessToken", accessToken);

//       if (user) {
//         const docRef = doc(db, "Users", user.uid);
//         const docSnap = await getDoc(docRef);

//         if (!docSnap.exists()) {
//           // Create new user
//           await setDoc(docRef, {
//             email: user.email,
//             name: user.displayName,
//             lastLogin: new Date(),
//             profilePicture: user.photoURL,
//           });
//         }

//         navigate("/home", {
//           state: { userDetail: docSnap.data(), accessToken },
//         });
//       }
//     } catch (error) {
//       if (error.code === "auth/account-exists-with-different-credential") {
//         const email = error.customData?.email;
//         const credential = GoogleAuthProvider.credentialFromError(error);

//         if (email) {
//           const providers = await fetchSignInMethodsForEmail(auth, email);
//           if (providers.length > 0) {
//             const firstProvider = providers[0];

//             alert(
//               `Your account is already registered with ${firstProvider}. Sign in with that first.`
//             );

//             const existingProvider = getProvider(firstProvider);

//             signInWithPopup(auth, existingProvider)
//               .then((existingUser) => {
//                 // Link Google to the existing provider
//                 linkWithCredential(existingUser.user, credential)
//                   .then(() => console.log("Accounts linked successfully"))
//                   .catch((linkError) =>
//                     console.error("Error linking accounts:", linkError)
//                   );
//               })
//               .catch((signInError) =>
//                 console.error(
//                   "Error signing in with existing provider:",
//                   signInError
//                 )
//               );
//           }
//         }
//       } else {
//         console.error("Google login error:", error);
//       }
//     }
//   }

//   return (
//     <div className="google-login-container">
//       <p className="or-text">---or continue with---</p>
//       <button className="google-login-button" onClick={googleLogin}>
//         <img src={googleImg} alt="Google Logo" className="google-logo" />
//         <span className="google-login-text">Sign in with Google</span>
//       </button>
//     </div>
//   );
// }
