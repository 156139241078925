import { useNavigate } from "react-router-dom";
import facebookImg from "../assets/twitter.png";

import "../components/SignInWithTwitter.css";
import { useState } from "react";
import { signInWithPopup, TwitterAuthProvider } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { auth, db } from "../config/firebase";

// export default function SignInWithTwitter() {
//   const navigate = useNavigate();

//   const [showPopup, setShowPopup] = useState(false);
//   const [providerName, setProviderName] = useState("");

//   const handleClose = () => {
//     setShowPopup(false);
//   };

//   const TwitterLogin = async () => {
//     const provider = new TwitterAuthProvider();

//     try {
//       const result = await signInWithPopup(auth, provider);
//       const user = result.user;

//       console.log(user);

//       const accessToken = await user.getIdToken(true); // Fetch the token

//       localStorage.setItem("accessToken", accessToken);

//       console.log(`ACCESS TOKEN: ${accessToken}`);
//       if (user) {
//         const docRef = doc(db, "Users", user.uid);
//         const docSnap = await getDoc(docRef);

//         if (docSnap.exists()) {
//           const savedToken = localStorage.getItem("accessToken");

//           console.log(docSnap.data());

//           // User data exists
//           navigate("/home", {
//             state: { userDetail: docSnap.data(), accessToken: savedToken },
//           });

//           // User is signed in
//           const providerData = user.providerData;

//           // Iterate through provider data to get provider ID
//           providerData.forEach((profile) => {
//             const providerId = profile.providerId;
//             console.log("Provider ID:", providerId);
//           });
//         } else {
//           // If no user exists, create one in Firestore
//           const newUser = {
//             email: user.email || "",
//             name: user.displayName || "Anonymous",
//             lastLogin: new Date(),
//             profilePicture: user.photoURL || "",
//           };
//           // If no user data, create a new document
//           await setDoc(docRef, newUser);

//           const savedToken = localStorage.getItem("accessToken");
//           navigate("/home", {
//             state: {
//               userDetail: {
//                 email: user.email,
//                 name: user.displayName,
//                 profilePicture: user.photoURL,
//               },
//               accessToken: savedToken,
//             },
//           });
//         }

//         // User is signed in
//         const providerData = user.providerData;

//         // Iterate through provider data to get provider ID
//         providerData.forEach((profile) => {
//           const providerId = profile.providerId;
//           console.log("Provider ID:", providerId);
//         });
//       }
//     } catch (error) {
//       console.error("Error during sign-in:", error);
//       console.log("Full error object:", JSON.stringify(error, null, 2));

//       // Check the error object for more detailed information
//       const customData = error.customData;
//       if (customData && customData._tokenResponse) {
//         const verifiedProviders = customData._tokenResponse.verifiedProvider;

//         if (verifiedProviders && verifiedProviders.length > 0) {
//           const providerId = verifiedProviders[0];

//           let providerName = "";
//           switch (providerId) {
//             case "google.com":
//               providerName = "Google";
//               break;
//             case "facebook.com":
//               providerName = "Facebook";
//               break;
//             case "twitter.com":
//               providerName = "Twitter";
//               break;
//             // Add more cases for other providers as needed
//             default:
//               providerName = "Unknown Provider";
//           }
//           console.log("Verified Provider Name:", providerName);

//           // Show the popup with the message
//           setProviderName(providerName);
//           setShowPopup(true);
//         } else {
//           console.log("No verified providers found.");
//         }
//       } else {
//         console.log("Provider information not available in the error.");
//       }
//     }
//   };

//   return (
//     <div>
//       <div className="twitter-login-container" onClick={TwitterLogin}>
//         {/* <p className="or-text">---or---</p> */}
//         <button className="twitter-login-button">
//           <img src={facebookImg} alt="Twitter Logo" className="twitter-logo" />
//           <span className="twitter-login-text">Sign in with X</span>
//         </button>
//       </div>

//       {showPopup && (
//         <div className="popup">
//           <div className="popup-content">
//             <span className="close" onClick={handleClose}>
//               &times;
//             </span>
//             <h2>Account Exists</h2>
//             <p>{`You already have an account with ${providerName}. Please sign in using that provider.`}</p>
//             <button onClick={handleClose}>OK</button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// export default function SignInWithTwitter() {
//   const navigate = useNavigate();

//   const [showPopup, setShowPopup] = useState(false);
//   const [providerName, setProviderName] = useState("");

//   const handleClose = () => {
//     setShowPopup(false);
//   };

//   const TwitterLogin = async () => {
//     const provider = new TwitterAuthProvider();

//     try {
//       const result = await signInWithPopup(auth, provider);
//       const user = result.user;

//       console.log("Full User Object:", user);

//       console.log("Name:", user.displayName);

//       console.log("Email:", user.providerData[0].email);

//       // 🔍 Check for email explicitly
//       console.log("Email:", user.email || "Email not provided");

//       const accessToken = await user.getIdToken(true); // Fetch the token

//       localStorage.setItem("accessToken", accessToken);

//       console.log(`ACCESS TOKEN: ${accessToken}`);
//       if (user) {
//         const docRef = doc(db, "Users", user.uid);
//         const docSnap = await getDoc(docRef);

//         if (docSnap.exists()) {
//           const savedToken = localStorage.getItem("accessToken");

//           console.log(docSnap.data());

//           // User data exists
//           navigate("/home", {
//             state: { userDetail: docSnap.data(), accessToken: savedToken },
//           });

//           // User is signed in
//           const providerData = user.providerData;

//           // Iterate through provider data to get provider ID
//           providerData.forEach((profile) => {
//             const providerId = profile.providerId;
//             console.log("Provider ID:", providerId);
//           });
//         } else {
//           // If no user exists, create one in Firestore
//           const newUser = {
//             email: user.email || "",
//             name: user.displayName || "Anonymous",
//             lastLogin: new Date(),
//             profilePicture: user.photoURL || "",
//           };
//           // If no user data, create a new document
//           await setDoc(docRef, newUser);

//           const savedToken = localStorage.getItem("accessToken");
//           navigate("/home", {
//             state: {
//               userDetail: {
//                 email: user.email,
//                 name: user.displayName,
//                 profilePicture: user.photoURL,
//               },
//               accessToken: savedToken,
//             },
//           });
//         }

//         // User is signed in
//         const providerData = user.providerData;

//         // Iterate through provider data to get provider ID
//         providerData.forEach((profile) => {
//           const providerId = profile.providerId;
//           console.log("Provider ID:", providerId);
//         });
//       }
//     } catch (error) {
//       console.error("Error during sign-in:", error);
//       console.log("Full error object:", JSON.stringify(error, null, 2));

//       // Check the error object for more detailed information
//       const customData = error.customData;
//       if (customData && customData._tokenResponse) {
//         const verifiedProviders = customData._tokenResponse.verifiedProvider;

//         if (verifiedProviders && verifiedProviders.length > 0) {
//           const providerId = verifiedProviders[0];

//           let providerName = "";
//           switch (providerId) {
//             case "google.com":
//               providerName = "Google";
//               break;
//             case "facebook.com":
//               providerName = "Facebook";
//               break;
//             case "twitter.com":
//               providerName = "Twitter";
//               break;
//             // Add more cases for other providers as needed
//             default:
//               providerName = "Unknown Provider";
//           }
//           console.log("Verified Provider Name:", providerName);

//           // Show the popup with the message
//           setProviderName(providerName);
//           setShowPopup(true);
//         } else {
//           console.log("No verified providers found.");
//         }
//       } else {
//         console.log("Provider information not available in the error.");
//       }
//     }
//   };

export default function SignInWithTwitter() {
  const navigate = useNavigate();

  const [showPopup, setShowPopup] = useState(false);
  const [providerName, setProviderName] = useState("");

  const handleClose = () => {
    setShowPopup(false);
  };

  const TwitterLogin = async () => {
    const provider = new TwitterAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      console.log("Full User Object:", user);

      console.log("Name:", user.displayName);

      console.log("Email:", user.providerData[0].email);

      // 🔍 Check for email explicitly
      console.log("Email:", user.providerData[0].email || "Email not provided");

      const accessToken = await user.getIdToken(true); // Fetch the token

      localStorage.setItem("accessToken", accessToken);

      console.log(`ACCESS TOKEN: ${accessToken}`);
      if (user) {
        const docRef = doc(db, "Users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const savedToken = localStorage.getItem("accessToken");

          console.log(docSnap.data());

          // User data exists
          navigate("/home", {
            state: { userDetail: docSnap.data(), accessToken: savedToken },
          });

          // User is signed in
          const providerData = user.providerData;

          // Iterate through provider data to get provider ID
          providerData.forEach((profile) => {
            const providerId = profile.providerId;
            console.log("Provider ID:", providerId);
          });
        } else {
          // If no user exists, create one in Firestore
          const newUser = {
            email: user.providerData[0].email || "",
            name: user.displayName || "Anonymous",
            lastLogin: new Date(),
            profilePicture: user.photoURL || "",
          };
          // If no user data, create a new document
          await setDoc(docRef, newUser);

          const savedToken = localStorage.getItem("accessToken");
          navigate("/home", {
            state: {
              userDetail: {
                email: user.providerData[0].email,
                name: user.displayName,
                profilePicture: user.photoURL,
              },
              accessToken: savedToken,
            },
          });
        }

        // User is signed in
        const providerData = user.providerData;

        // Iterate through provider data to get provider ID
        providerData.forEach((profile) => {
          const providerId = profile.providerId;
          console.log("Provider ID:", providerId);
        });
      }
    } catch (error) {
      console.error("Error during sign-in:", error);
      console.log("Full error object:", JSON.stringify(error, null, 2));

      // Check the error object for more detailed information
      const customData = error.customData;
      if (customData && customData._tokenResponse) {
        const verifiedProviders = customData._tokenResponse.verifiedProvider;

        if (verifiedProviders && verifiedProviders.length > 0) {
          const providerId = verifiedProviders[0];

          let providerName = "";
          switch (providerId) {
            case "google.com":
              providerName = "Google";
              break;
            case "facebook.com":
              providerName = "Facebook";
              break;
            case "twitter.com":
              providerName = "Twitter";
              break;
            // Add more cases for other providers as needed
            default:
              providerName = "Unknown Provider";
          }
          console.log("Verified Provider Name:", providerName);

          // Show the popup with the message
          setProviderName(providerName);
          setShowPopup(true);
        } else {
          console.log("No verified providers found.");
        }
      } else {
        console.log("Provider information not available in the error.");
      }
    }
  };

  return (
    <div>
      <div className="twitter-login-container" onClick={TwitterLogin}>
        {/* <p className="or-text">---or---</p> */}
        <button className="twitter-login-button">
          <img src={facebookImg} alt="Twitter Logo" className="twitter-logo" />
          <span className="twitter-login-text">Sign in with X</span>
        </button>
      </div>

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <span className="close" onClick={handleClose}>
              &times;
            </span>
            <h2>Account Exists</h2>
            <p>{`You already have an account with ${providerName}. Please sign in using that provider.`}</p>
            <button onClick={handleClose}>OK</button>
          </div>
        </div>
      )}
    </div>
  );
}
