export const createFolderTree = (data) => {
    const tree = {};
    const rootFolders = new Set();
  
    data.forEach((item) => {
      if (!item.name) return; // Skip if name is undefined or empty
  
      const parts = item.name.split("/");
  
      if (parts.length > 1) {
        rootFolders.add(parts[0]);
      }
  
      let currentLevel = tree;
      parts.slice(0, -1).forEach((part) => {
        if (!currentLevel[part]) {
          currentLevel[part] = {};
        }
        currentLevel = currentLevel[part];
      });
    });
  
    return { tree, rootFolders: Array.from(rootFolders) };
  };