import { useNavigate } from "react-router-dom";
import facebookImg from "../assets/facebook.png";

import "../components/SignInWithFaceBook.css";
import {
  FacebookAuthProvider,
  fetchSignInMethodsForEmail,
  getRedirectResult,
  GoogleAuthProvider,
  linkWithCredential,
  reauthenticateWithCredential,
  signInWithPopup,
  signInWithRedirect,
} from "firebase/auth";
import { auth, db } from "../config/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";

// export default function SignInWithFaceBook() {
//   const navigate = useNavigate();

//   async function facebookLogin() {
//     const provider = new FacebookAuthProvider();
//     try {
//       const result = await signInWithPopup(auth, provider);
//       const user = result.user;

//       console.log(user);

//       const accessToken = await user.getIdToken(true); // Fetch the token

//       localStorage.setItem("accessToken", accessToken);

//       console.log(`ACCESS TOKEN: ${accessToken}`);
//       if (user) {
//         const docRef = doc(db, "Users", user.uid);
//         const docSnap = await getDoc(docRef);

//         if (docSnap.exists()) {
//           const savedToken = localStorage.getItem("accessToken");

//           console.log(docSnap.data());

//           // User data exists
//           navigate("/home", {
//             state: { userDetail: docSnap.data(), accessToken: savedToken },
//           });
//         } else {
//           // If no user exists, create one in Firestore
//           const newUser = {
//             email: user.email || "",
//             name: user.displayName || "Anonymous",
//             lastLogin: new Date(),
//             profilePicture: user.photoURL || "",
//           };
//           // If no user data, create a new document
//           await setDoc(docRef, newUser);

//           const savedToken = localStorage.getItem("accessToken");
//           navigate("/home", {
//             state: {
//               userDetail: {
//                 email: user.email,
//                 name: user.displayName,
//                 profilePicture: user.photoURL,
//               },
//               accessToken: savedToken,
//             },
//           });
//         }
//       }
//     } catch (error) {
//       console.error("Error during Google login:", error);
//       console.error("Error during Google login:", error.customData.email);
//     }
//   }

//   return (
//     <div className="facebook-login-container" onClick={facebookLogin}>
//       <p className="or-text">---or---</p>
//       <button className="facebook-login-button">
//         <img src={facebookImg} alt="Google Logo" className="facebook-logo" />
//         <span className="facebook-login-text">Sign in with FaceBook</span>
//       </button>
//     </div>
//   );
// }

// // -----------------------------------------------------------------

// const getProviderName = (providerId) => {
//   switch (providerId) {
//     case "google.com":
//       return "Google";
//     case "facebook.com":
//       return "Facebook";
//     case "twitter.com":
//       return "Twitter";
//     case "github.com":
//       return "GitHub";
//     default:
//       return "another provider";
//   }
// };

// export default function SignInWithFaceBook() {
//   const navigate = useNavigate();

//   const facebookLogin = async () => {
//     const provider = new FacebookAuthProvider();

//     try {
//       const result = await signInWithPopup(auth, provider);
//       const user = result.user;

//       console.log(user);

//       const accessToken = await user.getIdToken(true); // Fetch the token

//       localStorage.setItem("accessToken", accessToken);

//       console.log(`ACCESS TOKEN: ${accessToken}`);
//       if (user) {
//         const docRef = doc(db, "Users", user.uid);
//         const docSnap = await getDoc(docRef);

//         if (docSnap.exists()) {
//           const savedToken = localStorage.getItem("accessToken");

//           console.log(docSnap.data());

//           // User data exists
//           navigate("/home", {
//             state: { userDetail: docSnap.data(), accessToken: savedToken },
//           });

//           // User is signed in
//           const providerData = user.providerData;

//           // Iterate through provider data to get provider ID
//           providerData.forEach((profile) => {
//             const providerId = profile.providerId;
//             console.log("Provider ID:", providerId);
//           });
//         } else {
//           // If no user exists, create one in Firestore
//           const newUser = {
//             email: user.email || "",
//             name: user.displayName || "Anonymous",
//             lastLogin: new Date(),
//             profilePicture: user.photoURL || "",
//           };
//           // If no user data, create a new document
//           await setDoc(docRef, newUser);

//           const savedToken = localStorage.getItem("accessToken");
//           navigate("/home", {
//             state: {
//               userDetail: {
//                 email: user.email,
//                 name: user.displayName,
//                 profilePicture: user.photoURL,
//               },
//               accessToken: savedToken,
//             },
//           });
//         }

//         // User is signed in
//         const providerData = user.providerData;

//         // Iterate through provider data to get provider ID
//         providerData.forEach((profile) => {
//           const providerId = profile.providerId;
//           console.log("Provider ID:", providerId);
//         });
//       }
//     } catch (error) {
//       console.error("❌ Error during Facebook login:", error);

//       if (error.code === "auth/account-exists-with-different-credential") {
//         const email = error.customData?.email;

//         if (email) {
//           console.log("⚠️ Fetching provider info for:", email);

//           // Reload user data to ensure we have up-to-date provider info
//           await auth.currentUser?.reload();
//           console.log("🔄 User reloaded. Checking providers...");

//           const updatedUser = auth.currentUser;
//           console.log("🔥 Updated User Object:", updatedUser);

//           const providers = updatedUser?.providerData.map((p) => p.providerId);
//           console.log("🔥 Current User Providers:", providers);

//           if (providers?.length > 0) {
//             const providerName = getProviderName(providers[0]);
//             alert(
//               `Your account is linked to ${providerName}. Please log in with ${providerName} first.`
//             );
//           } else {
//             alert(
//               "Your account exists but isn't linked. Try signing in with another provider."
//             );
//           }
//         }
//       }
//     }
//   };

//   return (
//     <div className="facebook-login-container" onClick={facebookLogin}>
//       <p className="or-text">---or---</p>
//       <button className="facebook-login-button">
//         <img src={facebookImg} alt="Google Logo" className="facebook-logo" />
//         <span className="facebook-login-text">Sign in with FaceBook</span>
//       </button>
//     </div>
//   );
// }

// -----------------------------------------------------------------

export default function SignInWithFaceBook() {
  const navigate = useNavigate();

  const [showPopup, setShowPopup] = useState(false);
  const [providerName, setProviderName] = useState("");

  const handleClose = () => {
    setShowPopup(false);
  };

  const facebookLogin = async () => {
    const provider = new FacebookAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      console.log(user);

      const accessToken = await user.getIdToken(true); // Fetch the token

      localStorage.setItem("accessToken", accessToken);

      console.log(`ACCESS TOKEN: ${accessToken}`);
      if (user) {
        const docRef = doc(db, "Users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const savedToken = localStorage.getItem("accessToken");

          console.log(docSnap.data());

          // User data exists
          navigate("/home", {
            state: { userDetail: docSnap.data(), accessToken: savedToken },
          });

          // User is signed in
          const providerData = user.providerData;

          // Iterate through provider data to get provider ID
          providerData.forEach((profile) => {
            const providerId = profile.providerId;
            console.log("Provider ID:", providerId);
          });
        } else {
          // If no user exists, create one in Firestore
          const newUser = {
            email: user.email || "",
            name: user.displayName || "Anonymous",
            lastLogin: new Date(),
            profilePicture: user.photoURL || "",
          };
          // If no user data, create a new document
          await setDoc(docRef, newUser);

          const savedToken = localStorage.getItem("accessToken");
          navigate("/home", {
            state: {
              userDetail: {
                email: user.email,
                name: user.displayName,
                profilePicture: user.photoURL,
              },
              accessToken: savedToken,
            },
          });
        }

        // User is signed in
        const providerData = user.providerData;

        // Iterate through provider data to get provider ID
        providerData.forEach((profile) => {
          const providerId = profile.providerId;
          console.log("Provider ID:", providerId);
        });
      }
    } catch (error) {
      console.error("Error during sign-in:", error);
      console.log("Full error object:", JSON.stringify(error, null, 2));

      // Check the error object for more detailed information
      const customData = error.customData;
      if (customData && customData._tokenResponse) {
        const verifiedProviders = customData._tokenResponse.verifiedProvider;

        if (verifiedProviders && verifiedProviders.length > 0) {
          const providerId = verifiedProviders[0];

          let providerName = "";
          switch (providerId) {
            case "google.com":
              providerName = "Google";
              break;
            case "facebook.com":
              providerName = "Facebook";
              break;
            case "github.com":
              providerName = "GitHub";
              break;
            // Add more cases for other providers as needed
            default:
              providerName = "Unknown Provider";
          }
          console.log("Verified Provider Name:", providerName);

          // Show the popup with the message
          setProviderName(providerName);
          setShowPopup(true);
        } else {
          console.log("No verified providers found.");
        }
      } else {
        console.log("Provider information not available in the error.");
      }
    }
  };

  return (
    <div>
      <div className="facebook-login-container" onClick={facebookLogin}>
        {/* <p className="or-text">---or---</p> */}
        <button className="facebook-login-button">
          <img
            src={facebookImg}
            alt="Facebook Logo"
            className="facebook-logo"
          />
          <span className="facebook-login-text">Sign in with FaceBook</span>
        </button>
      </div>

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <span className="close" onClick={handleClose}>
              &times;
            </span>
            <h2>Account Exists</h2>
            <p>{`You already have an account with ${providerName}. Please sign in using that provider.`}</p>
            <button onClick={handleClose}>OK</button>
          </div>
        </div>
      )}
    </div>
  );
}

// ---------------------------------   UP CORRECT ---------------
