import axios from "axios";
export const CREATEFAVS_API_HOST_URL = process.env.REACT_APP_CREATEFAVS_API_URL


export default async function FavoritesCreate(accessToken, selectedFiles) {
    let returnResponse = {
        data: [],
        result: false,
        status: ""
    };
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };

    let _favsItemList = selectedFiles.map ((file) =>{
        let filePath = file.path.startsWith("/") ? file.path.slice(1) : file.path
        return filePath;
    })

    const favsCreatePayload = {
        favsItemList : _favsItemList
    };

    try {
        const response = await axios.post(
            `${CREATEFAVS_API_HOST_URL}`,
            favsCreatePayload,
            { headers: headers }
        );

        if (response.status === 200) {
            returnResponse.data = response.data;
            returnResponse.status = response.status;
            returnResponse.result = true;
        } else {
            console.error('Request failed with status:', response.status);
            returnResponse.status = response.status;
        }
    } catch (error) {
        console.error('Request error:', error);
        returnResponse.status = error;
    }
    return returnResponse;
}