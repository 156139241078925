import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  files: [],
  currentItems: [],
  multipleFileSelect: [],
};

const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    
    setFilesStore(state, action) {
      state.files = [...action.payload];
    },
    clearFiles(state) {
      state.files = [];
    },
    removeFile(state, action) {
      const fileToRemove = action.payload.toLowerCase().trim();
      state.files = state.files.filter(file =>
        file.name.toLowerCase().trim() !== fileToRemove);
    },
    removeFiles(state, action) {
      const filesToRemove = action.payload;
      filesToRemove.map(fileToRemove => {
        state.files = state.files.filter(file =>
          file.name.toLowerCase().trim() !== fileToRemove.toLowerCase().trim()
        );
      });
    },

    addFile(state, action) {
      //console.log("store", action.payload);
      const fileToAdd = action.payload.name?.toLowerCase().trim();
      if (!state.files.some(file => file.name.toLowerCase().trim() === fileToAdd)) {
        state.files.push(fileToAdd);
      }
    },

    updateFiles(state,action){
      const updatedFile = action.payload;
      console.log("Up", updatedFile);
      state.files = state.files.map(file => {
        const matchingUpdate = updatedFile.find(
          (updatedFile) => updatedFile.name === file.name
        );
        return matchingUpdate ? { ...file, ...matchingUpdate } : file;
      });
  },
    setCurrentItems(state, action) {
      state.currentItems = [...action.payload];
    },
    clearCurrentItems(state) {
      state.currentItems = [];
    },

    updateCurrentItems(state, action) {
      const updatedItems = action.payload; // Array of items to update
      state.currentItems = state.currentItems.map((item) => {
        const matchingUpdate = updatedItems.find(
          (updatedItem) => updatedItem.name === item.name
        );
        return matchingUpdate ? { ...item, ...matchingUpdate } : item;
      });
    },

    removeItemFromCurrentItems(state, action) {
      const itemToRemove = action.payload.toLowerCase().trim();
      console.log("kamal", itemToRemove);
      state.currentItems = state.currentItems.filter(item =>
        item.path.toLowerCase().trim() !== itemToRemove
      );
    },
    setMultipleFileSelect:(state,action) =>{
      state.multipleFileSelect = [...action.payload];
    },
    
    clearMultipleFileSelect:(state)=>{
      state.multipleFileSelect = [];
    },

    addSelectedFile:(state, action)=>{
      const selecetedFile = action.payload;
      if(!state.multipleFileSelect.some((file)=>file.path === selecetedFile.path)){
        state.multipleFileSelect.push(selecetedFile);
      }
    },

    removeSelectedFile:(state, action)=>{
      const selectedFileToRemove = action.payload;
      state.multipleFileSelect = state.multipleFileSelect.filter((file)=>
        file.path !== selectedFileToRemove.path
      );
    },
  },
});

export const {
  setMultipleFileSelect,
  clearMultipleFileSelect,
  addSelectedFile,
  removeSelectedFile,
  setFilesStore,
  clearFiles,
  removeFile,
  removeFiles,
  addFile,
  updateFiles,
  setCurrentItems,
  clearCurrentItems,
  updateCurrentItems,
  removeItemFromCurrentItems,
} = filesSlice.actions;

export default filesSlice.reducer;