import { NavigateNext } from "@mui/icons-material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useTranslation } from 'react-i18next';

const BreadcrumbComponent = ({ path, onPathChange }) => {
  const { t } = useTranslation();
  // Remove trailing slash from the path if it exists
  const cleanedPath = path.endsWith("/") ? path.slice(0, -1) : path;
  const pathParts = cleanedPath
    ? [t("Home"), ...cleanedPath.split("/")]
    : [t("Home")];

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      // separator=">"
      separator={<NavigateNext style={{ backgroundColor: "white" }} />}
      className="breadcrumb-container" // Apply specific class for breadcrumbs container
      sx={{
        padding: "8px 16px", // Add padding around the breadcrumbs
        backgroundColor: "white", // Force the background to white
      }}
    >
      {pathParts.map((part, index) => {
        const updatedPath =
          index === 0
            ? "" // Root "Home" corresponds to an empty path
            : pathParts.slice(1, index + 1).join("/");

        const isLast = index === pathParts.length - 1;

        return isLast ? (
          <Typography
            key={index}
            color="black"
            sx={{
              fontWeight: "500",
              fontSize: "15px",
              padding: "0px !important",
              color: "#007FFF",
              cursor: "pointer",
              letterSpacing: "1px",
              fontFamily: "Montserrat",
            }}
          >
            {part}
          </Typography>
        ) : (
          <Link
            key={index}
            // underline="none"
            underline="hover"
            // color="inherit"
            color="#28282B"
            fontWeight="500"
            onClick={() => onPathChange(updatedPath)}
            sx={{
              cursor: "pointer",
              "&:hover": {
                color: `#0066b2`,
              },
              letterSpacing: "1px",
              fontSize: "15px",
            }}
          >
            {part}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default BreadcrumbComponent;