import React from "react";
import { COMPANY_LOGO_SQUARE } from "../../components/APP_LOGO/app_logo";

export default function LogoSection({ handelLogoClick }) {
  return (
    <div id="imgCompanyLogo" className="header1-logo">
      <img
        src={COMPANY_LOGO_SQUARE}
        alt="Company Logo"
        onClick={handelLogoClick}
        draggable="false"
        className="company-logo"
        style={{ width: "35px" }}
      />
      <span id="mainHeaderDrive">Drive</span>
    </div>
  );
}
